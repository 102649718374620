import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    userCollection: [],
    globalCollection: [],
    userTags: [],
    globalTags: []
}

export const memesCollectionSlice = createSlice({
    name: 'memesCollection',
    initialState,
    reducers: {
        setUserCollection: (state, action) => {
            state.userCollection = action.payload;
        },
        addMemeToUserCollection: (state, action) => {
            state.userCollection.unshift(action.payload);
        },
        removeFromUserCollection: (state, action) => {
            state.userCollection =  state.userCollection.filter(meme => meme.id !== action.payload);
        },
        setGlobalCollection: (state, action) => {
            state.globalCollection = action.payload;
        },
        setUserTags: (state, action) => {
            state.userTags = action.payload;
        },
        setGlobalTags: (state, action) => {
            state.globalTags = action.payload;
        },
        updateMemeFromUserCollection: (state, action) => {
            state.userCollection = state.userCollection.map(meme => {
                if (meme.id === action.payload.id) {
                    return action.payload;
                }
                return meme;
            })
        },
        updateMemeFromGlobalCollection: (state, action) => {
            state.globalCollection = state.globalCollection.map(meme => {
                if (meme.id === action.payload.id) {
                    return action.payload;
                }
                return meme;
            })
        }
    },
})

export const {
    setUserCollection,
    setGlobalCollection,
    addMemeToUserCollection,
    removeFromUserCollection,
    setUserTags,
    setGlobalTags,
    updateMemeFromUserCollection,
    updateMemeFromGlobalCollection
} = memesCollectionSlice.actions

export default memesCollectionSlice.reducer