import React, {useEffect, useState} from "react";
import Api from "../../actions/apiCallHelper";
import {Input} from "../Common/Input/Input";
import {useNavigate, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";
import {Button} from "../Common/Button/Button";

export const ResetPasswordWindow = () => {

    const {keyLink} = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState();
    const [passwordAgain, setPasswordAgain] = useState();

    const [timerForRedirect, setTimerForRedirect] = useState();

    useEffect(() => {
        const checkKeyLink = async () => {
            const api = new Api();
            try {
                const response = await api.checkResetPasswordKey(keyLink);

            } catch (e) {
                console.log(e);
            }
        }
        checkKeyLink();
    }, []);

    const handleSubmit = async e => {
        e.preventDefault();

        const api = new Api();

        if (password !== passwordAgain) {
            toast('Пароли не совпадают!');
            return;
        }

        try {
            const response = await api.restorePassword({
                'keyLink': keyLink,
                'password': password
            });
            setPassword(null);
            setPasswordAgain(null);
            toast('Пароль успешно обновлен!');
        } catch (e) {
            toast(e.message);
            console.log(e);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='login-window'>
                <span className='signin-header'>Восстановление пароля</span>
                <div className='signup-inputs'>
                    <Input
                        placeholder='Пароль'
                        type='password'
                        value={password}
                        setValue={setPassword}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Input
                        placeholder='Повторите пароль'
                        type='password'
                        value={passwordAgain}
                        setValue={setPasswordAgain}
                        onChange={e => setPasswordAgain(e.target.value)}
                    />
                </div>
                <Button fontSize={16} text='Изменить пароль' submit={true}/>
            </form>
        </>
    )
}