import {useLocation, useNavigate} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {useState} from "react";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import {Constants} from "../../helpers/Constants";
import {copyMemeImg, shareMeme} from "../../helpers/memeHelper";
import copyMemeSvg from "../../assets/images/copy-meme.svg";
import shareMemeSvg from "../../assets/images/share.svg";
import editMemeSvg from "../../assets/images/edit.svg";
import deleteMemeSvg from "../../assets/images/delete.svg";
import pitySvg from "../../assets/images/pity.svg";
import {UserRow} from "../User/UserRow";
import {ContextMenuButton} from "../Common/ContextMenu/ContextMenuButton";
import {ContextMenuOption} from "../Common/ContextMenu/ContextMenuOption";
import {Modal} from "../Common/Modal/Modal";
import {YesNoDialog} from "../Common/YesNoDialog/YesNoDialog";
import './CardCreator.css';
import {removeFromUserCollection} from "../../store/memesCollectionSlice";
import {useDispatch} from "react-redux";


export const CardCreator = ({meme, callbackRemove}) => {

    const location = useLocation();
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [modalActive, setModalActive] = useState(false);
    const [canSendPity, setCanSendPity] = useState(true);

    const deleteButtonHandler = async (memeId) => {
        const api = new Api(true);
        try {
            await api.removeMemeById(memeId);
            setModalActive(false);
            callbackRemove(memeId);
            dispatch(removeFromUserCollection(memeId));
        } catch (e) {
            toast(e.message);
        } finally {
            setModalActive(false);
        }
    }

    const sendPity = async (memeFileId) => {
        const api = new Api(user);
        try {
            await api.sendPity(memeFileId);
            toast('Жалоба отправлена');
            setCanSendPity(false);
        } catch (e) {
            if (e.code === 400) {
                setCanSendPity(false);
                toast(e.message);
            }
            console.log(e);
        }
    }


    const edit = () => {
        navigate(Constants.ROUTES.meme_edit + meme.id);
    }

    return (
        <>
            <div className='card-creator'>
                <UserRow user={meme.owner}/>

                {!meme.temp &&
                    <ContextMenuButton>
                        <ContextMenuOption
                            handler={() => copyMemeImg(meme.memeFile)}
                            imgSrc={copyMemeSvg}
                            alt={'copy meme'}
                            text="Скопировать мем"
                        />
                        <ContextMenuOption
                            handler={() => shareMeme(meme.id)}
                            imgSrc={shareMemeSvg}
                            alt={'share meme'}
                            text="Поделиться"
                        />
                        {canSendPity && user?.id !== meme.owner.id &&
                            <ContextMenuOption
                                handler={() => sendPity(meme.memeFile.id)}
                                imgSrc={pitySvg}
                                alt={'send pity'}
                                text="Жалоба"
                            />
                        }
                        {(meme?.owner?.id === user?.id && location.pathname !== (Constants.ROUTES.meme_edit + meme.id)) &&
                            <>
                                <ContextMenuOption
                                    handler={edit}
                                    imgSrc={editMemeSvg}
                                    alt={'edit meme'}
                                    text="Редактировать"
                                />

                                <ContextMenuOption
                                    handler={() => {
                                        setModalActive(true);
                                    }}
                                    imgSrc={deleteMemeSvg}
                                    alt={'delete meme'}
                                    text="Удалить"
                                />
                            </>
                        }
                    </ContextMenuButton>
                }
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <YesNoDialog
                    message="Вы уверены что хотите удалить этот мем?"
                    okAction={async () => {
                        await deleteButtonHandler(meme.id);
                        setModalActive(false);
                    }}
                    cancelAction={() => setModalActive(false)}
                />
            </Modal>
        </>
    );
}