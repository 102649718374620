import React from "react";
import {Header} from "../../components/Header/Header";
import './RegistrationPage.css';
import SignUpWindow from "../../components/SignUpWindow/SignUpWindow";

export const RegistrationPage = () => {
    return (
        <>
            <Header/>
            <div className='app-global-container'>
                <SignUpWindow/>
            </div>
        </>
    )
}