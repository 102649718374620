import React from "react";
import {AddCommentBlock} from "./AddCommentBlock";
import {CommentsList} from "./CommentsList";
import './CommentsBlock.css';

export const CommentsBlock = ({comments, addComment, deleteComment, meme}) => {
    return (
        <div className='meme-comment-block-wrapper'>
            <span className='comments-header'>Комментарии</span>
            <div className='meme-comment-block'>
                <AddCommentBlock addComment={addComment} meme={meme}/>
                <CommentsList comments={comments} deleteComment={deleteComment}/>
            </div>
        </div>
    )
}
