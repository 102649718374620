import {Constants} from "../../helpers/Constants";
import {FastAverageColor} from "fast-average-color";
import {useEffect, useRef, useState} from "react";

export const MemeContent = ({memeFile, width: widthPlayer = 298, height: heightPlayer = 320, containerRef}) => {

    const imgRef = useRef();
    const fac = new FastAverageColor();
    const [backgroundColorMeme, setBackgroundColorMeme] = useState(null);

    console.log(memeFile);


    useEffect(() => {
        console.log('create meme content');
        return (() => {
            console.log('destroy meme content');
        });
    }, []);

    useEffect(() => {
        const container = containerRef.current;
        if (backgroundColorMeme !== null) {
            // container.style.transition = 'background-color 1s';
            container.style.backgroundColor = backgroundColorMeme.rgba.slice(0, -2) + '0.9)';
        }

    }, [backgroundColorMeme])


    switch (memeFile?.type) {
        case Constants.IMAGE_TYPE:
            return <img
                ref={imgRef}
                id={'meme-' + memeFile.id}
                src={memeFile.fileLink}
                alt='' crossOrigin='anonymous'
                onLoad={() => setBackgroundColorMeme(fac.getColor(imgRef.current))}
            />;
        case Constants.VIDEO_TYPE:
            return (
                <video id={'meme-' + memeFile.id} width={widthPlayer} height={heightPlayer} controls>
                    <source src={memeFile.fileLink} type="video/mp4"/>
                    Ваш браузер не поддерживает video тег
                </video>
            );
        case Constants.VIDEO_YOUTUBE_TYPE:
            return (
                <iframe id={'meme-' + memeFile.id}  width={widthPlayer} height={heightPlayer} src={memeFile.fileLink}
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                </iframe>
            );
        case Constants.VIDEO_VK_TYPE:
            return (
                <iframe id={'meme-' + memeFile.id}  width={widthPlayer} height={heightPlayer} src={memeFile.fileLink}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen>
                </iframe>
            );

        default:
            return <img id={'meme-' + memeFile?.id} className='card-img' src={memeFile?.fileLink} alt=''/>;
    }
}