import searchSvg from "../../../assets/images/search.svg";
import React from "react";
import './SearchInput.css';

export const SearchInput = ({onChange, handleKey, placeholder, width=292, className='input-field'}) => {

    const handleKeyEnter = (event) => {
        if (event.key === 'Enter') {
            handleKey();
        }
    }

    return (
        <div className={className} style={{minWidth: `${width}px`}}>
            <input
                type="search"
                className="search-input"
                placeholder={placeholder}
                onChange={onChange}
                onKeyDown={handleKeyEnter}
            />
            <button
                type="submit"
                className='search-button'
            ><img src={searchSvg} alt='search'/></button>
        </div>
    )
}
