import './Header.css';
import {useLocation, useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useUser from "../../hooks/useUser";
import {useSelector} from "react-redux";
import {HeaderButton} from "./HeaderButton";
import {Constants} from "../../helpers/Constants";
import {NotificationList} from "../Notification/NotificationList";
import logo from '../../assets/images/logo_dog.svg';
import bell from '../../assets/images/bell.svg';
import search from '../../assets/images/search-bold.svg';
import {Button} from "../Common/Button/Button";
import {Avatar} from "../Avatar/Avatar";
import {UserMenu} from "./UserMenu/UserMenu";
import {SearchUserWindow} from "../SearchUsersWindow/SearchUserWindow";
import {HeaderBurger} from "./HeaderBurger";

export const Header = () => {

    const location = useLocation();
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const notifications = useSelector((state) => state.notification.notifications);
    const [showNotification, setShowNotification] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);

    const [showSearch, setShowSearch] = useState(false);

    const [countNotReadableNotification, setCountNotReadableNotification] = useState(0);

    const getNotification = () => {
        setShowNotification(!showNotification);
    }

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = (event) => {
            setWidth(event.target.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const count = notifications.filter((notification) => {
            return !notification.read;
        }).length;
        setCountNotReadableNotification(count);
    }, [notifications]);

    return (
        <>
            {width > 1300 ?
                <>
                    <div className="header">
                        <div className='header-container'>
                            <nav className='menu-wrapper'>
                                <div className='menu-logo pointer' onClick={() => navigate(Constants.ROUTES.home)}>
                                    <img src={logo} alt={''}/>
                                    <span>MemeStorage</span>
                                </div>

                                <div className='menu-main'>
                                    <HeaderButton text="Глобальная коллекция" path={Constants.ROUTES.home}/>
                                    <HeaderButton text="Моя коллекция" disabled={!user}
                                                  path={Constants.ROUTES.my_collection}/>
                                    <HeaderButton text="Профиль" disabled={!user}
                                                  path={Constants.ROUTES.profile + user?.name}/>
                                    <HeaderButton text="Мои Теги" disabled={!user} path={Constants.ROUTES.tag_create}/>
                                </div>
                                {user ?
                                    <div className='menu-profile'>
                                        <Button
                                            className='add-meme-button'
                                            onClick={() => {
                                                navigate(Constants.ROUTES.meme_create);
                                            }}
                                            text="Добавить мем"
                                            disabled={location.pathname === Constants.ROUTES.meme_create}
                                        />
                                        <button onClick={() => setShowSearch(!showSearch)} className='no-border-button'>
                                            <div className='notification-button-content'>
                                                <img src={search} alt=''/>
                                            </div>
                                        </button>
                                        <button onClick={getNotification} className='no-border-button'>
                                            <div className='notification-button-content'>
                                                <img src={bell} alt={'уведомления'}/>
                                            </div>
                                        </button>
                                        {countNotReadableNotification > 0 &&
                                            <span className='notification-button-count'>
                                        {countNotReadableNotification}
                                    </span>
                                        }
                                        <Avatar avatar={user?.avatar} width={48} height={48}
                                                onClick={() => setShowUserMenu(!showUserMenu)}/>
                                    </div> :
                                    <div className='menu-profile-unathorized'>
                                        <button className='default-white'
                                                onClick={() => navigate(Constants.ROUTES.signin)}>Вход
                                        </button>
                                        <button className='default'
                                                onClick={() => navigate(Constants.ROUTES.signup)}>Регистрация
                                        </button>
                                    </div>
                                }
                            </nav>
                            {showNotification &&
                                <NotificationList
                                    notifications={notifications}
                                    showNotification={showNotification}
                                    setShowNotification={setShowNotification}
                                />
                            }
                            {showUserMenu &&
                                <UserMenu user={user} showContextMenu={showUserMenu}
                                          setShowContextMenu={setShowUserMenu}/>
                            }
                            {showSearch &&
                                <SearchUserWindow active={showSearch} setActive={setShowSearch}/>
                            }
                        </div>
                    </div>
                    <div className='margin-top'>

                    </div>
                </> : <HeaderBurger/>
            }
        </>
    );
}