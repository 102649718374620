import {useEffect, useRef, useState} from "react";
import './TagPageCollection.css';
import {TagPage} from "../Tag/TagPage";

export const TagPageCollection = ({tags = [], deleteHandler}) => {


    return (
        <div className='tag-page-collection'>
            {tags.length ? tags.map(tag => (
                <TagPage tag={tag} key={tag.id} deleteHandler={deleteHandler}/>
            )) : ''}
        </div>
    );
}