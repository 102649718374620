import React, {useRef} from "react";
import './Button.css';

export const Button = ({
   text,
   onClick,
   className,
   fontSize = 14,
   submit=false,
   active=false,
   disabled=false,
   maxWidth=false
}) => {

    const buttonRef = useRef();

    if (active) {
        className += ' activeTab';
    }

    if (maxWidth) {
        className += ' max-width';
    }

    if (text === 'Добавить мем') {
        console.log('dsiabled', disabled);
    }

    return (
        <>
            <button
                ref={buttonRef}
                disabled={disabled}
                className={!className ? 'default' : `default ${className}`}
                onClick={onClick} type={submit ? 'submit' : 'button'}
                style={{fontSize: fontSize + 'px'}}
            >
                {text}
            </button>
        </>
    );
}