import {scrollToTop} from "../../helpers/common";
import './ScrollToTopButton.css';
import {useEffect, useState} from "react";
import {ReactComponent as ArrowUp} from "../../assets/images/arrow-up.svg";

export const ScrollToTopButton = () => {

    const [show, setShow] = useState(false);
    const maxOffset = 300;
    let [opacity, setOpacity] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;

        if (position === 0) {
            setOpacity(0);
            setShow(false);
            return;
        }
        setShow(true);
        if (position > maxOffset) {
            setOpacity(1);
            return;
        }

        setOpacity(position/maxOffset);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            {show ? <div
                className='scroll-to-top-button'
                onClick={() => {scrollToTop()}}
                style={{opacity: opacity}}
            >
                <ArrowUp className='arrow-up-icon'/>
            </div>: ''}
        </>
    );
}