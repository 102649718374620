import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useUser from "../../hooks/useUser";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import {Header} from "../../components/Header/Header";
import {Loader} from "../../components/Common/Loader/Loader";
import {BackButton} from "../../components/Common/BackButton/BackButton";
import {MemeCardDetail} from "../../components/MemeCard/MemeCardDetail";
import {CommentsBlock} from "../../components/Comments/CommentsBlock";
import {addComment, deleteComment} from "../../helpers/memeHelper";
import {NotFoundPage} from "../NotFoundPage/NotFoundPage";

export const DetailMemeFilePage = () => {

    const [meme, setMeme] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const { id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const {user, setUser} = useUser();

    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const api = new Api(user);
            try {
                let response = await api.getMemeFileById(id);
                const data = response.data;
                setMeme(data);
            } catch (e) {
                if (e.code === 404) {
                    setNotFound(true);
                }
                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [id]);

    const addCommentCallback = async (commentText, modeComment) => {
        console.log(modeComment);
        await addComment({
            commentText: commentText,
            memeFileId: meme.id,
            callback: (response) => {
                setMeme((oldStateMeme) => {
                    oldStateMeme.comments.unshift(response);
                    return {...oldStateMeme};
                });
            }
        });
    }

    const deleteCommentCallback = async (commentId) => {
        await deleteComment(commentId, () => {
            setMeme((oldStateMeme) => {
                oldStateMeme.comments = oldStateMeme.comments.filter(c => (c.id !== commentId));
                return {...oldStateMeme};
            });
            toast('Комментарий удален!');
        });
    }

    return (
        <>
            {!notFound ?
                <div>
                    <Header/>
                    <div>
                        {isLoading ? <Loader/> :
                            <div className='app-global-container'>
                                <div className='meme-detail-wrapper'>
                                    <BackButton text='Вернуться назад'/>
                                    <div className='meme-detail-content'>
                                        <MemeCardDetail meme={meme}/>
                                        <CommentsBlock
                                            addComment={addCommentCallback}
                                            deleteComment={deleteCommentCallback}
                                            comments={meme.comments}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                : <NotFoundPage/>
            }
        </>
    );
}