import avatarPlaceholder from '../../assets/images/placeholder.png';
import './UserRow.css';
import {useNavigate} from "react-router-dom";
import {Constants} from "../../helpers/Constants";
import {scrollToTop} from "../../helpers/common";
import {useDispatch} from "react-redux";
import {setUserSearchDefaultSettings} from "../../store/searchUserCollectionSlice";
import {setUserCollection, setUserTags} from "../../store/memesCollectionSlice";

export const UserRow = ({user}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onUserClick = () => {
        scrollToTop(() => {
            dispatch(setUserSearchDefaultSettings());
            dispatch(setUserCollection([]));
            dispatch(setUserTags([]));
            navigate(Constants.ROUTES.collection + user.name);
        })
    }

    return (
        <>
            <div className='user-wrapper'>
                <button onClick={onUserClick} className='user-row-button'>
                    <img
                        src={user.avatar ?? avatarPlaceholder}
                        alt='no photo'
                        width={32}
                        height={32}
                        className={'round'}
                    />
                    <span className='user-name'>
                      {user.name}
                    </span>
                </button>
            </div>
        </>
    );
}