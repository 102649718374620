import './App.css';
import {LoginPage} from "./pages/LoginPage/LoginPage";
import useAccessToken from "./hooks/useAccessToken";
import useRefreshToken from "./hooks/useRefreshToken";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {CreateMemePage} from "./pages/CreateMemePage/CreateMemePage";
import {CreateTagPage} from "./pages/CreateTagPage/CreateTagPage";
import {EditMemePage} from "./pages/EditMemePage/EditMemePage";
import {Toaster} from "react-hot-toast";
import {ProfilePage} from "./pages/ProfilePage/ProfilePage";
import {GlobalCollectionPage} from "./pages/GlobalCollectionPage/GlobalCollectionPage";
import {NotFoundPage} from "./pages/NotFoundPage/NotFoundPage";
import {UserCollectionPage} from "./pages/UserCollectionPage/UserCollectionPage";
import {RegistrationPage} from "./pages/RegistrationPage/RegistrationPage";
import {ResetPasswordPage} from "./pages/ResetPasswordPage/ResetPasswordPage";
import {DetailMemePage} from "./pages/DetailMemePage/DetailMemePage";
import {Constants} from "./helpers/Constants";
import {useEffect} from "react";
import {onMessage} from "firebase/messaging";
import {getFirebaseToken, messaging} from "./helpers/firebase";
import Api from "./actions/apiCallHelper";
import {useDispatch} from "react-redux";
import {addNotification, setNotifications} from "./store/notificationSlice";
import useUser from "./hooks/useUser";
import {EditProfilePage} from "./pages/EditProfilePage/EditProfilePage";
import {DetailMemeFilePage} from "./pages/DetailMemePage/DetailMemeFilePage";
import {MobileMenu} from "./components/MobileMenu/MobileMenu";

function App() {

    const {accessToken, setAccessToken} = useAccessToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();
    const dispatch = useDispatch();
    const {user, setUser} = useUser();

    onMessage(messaging, async (payload) => {
        console.log('Message received. ', payload);
        const messageContent = JSON.parse(payload.data.body);
        console.log('add notif', messageContent);
        const user = JSON.parse(localStorage.getItem('user'));
        if (messageContent.recipient_id === user.id) {
            dispatch(addNotification({notification: messageContent}));
        }
    });

    const getNotifications = async () => {
        const api = new Api(true);
        try {
            const response = await api.getNotifications();
            let notifications = response.data?.notifications ?? [];
            notifications = notifications.map((n) => n.content);
            notifications = notifications.filter((n) => n.recipient_id === user.id);
            console.log('notifications', notifications);
            dispatch(setNotifications({notifications: notifications}))
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if (user) {
            refreshTokenAndGetNotifications();
        }
    }, [user]);

    const refreshTokenAndGetNotifications = async () => {
        const firebaseToken = await getFirebaseToken();
        const api = new Api(true);
        try {
            await api.updateFireBaseToken(firebaseToken);
        } catch (e) {
            console.log(e);
        }
        await getNotifications();
    }

    const ProtectedRoute = ({redirectPath = '/signin', children}) => {
        const user = localStorage.getItem('user');
        if (!user) {
            return <Navigate to={redirectPath} replace />
        }
        return <Outlet />;
    }

    return (
        <div className="App">
            <Toaster/>
            <BrowserRouter>
                <Routes>
                    <Route path={Constants.ROUTES.home} element={<GlobalCollectionPage/>}/>
                    <Route path={Constants.ROUTES.signin} element={<LoginPage/>}/>
                    <Route path={Constants.ROUTES.signup} element={<RegistrationPage/>}/>
                    <Route path={`${Constants.ROUTES.collection}:login`} element={<UserCollectionPage/>}/>
                    <Route path={`${Constants.ROUTES.reset_password}:keyLink`} element={<ResetPasswordPage/>}/>
                    <Route path={`${Constants.ROUTES.meme_detail}:login/:id`} element={<DetailMemePage/>}/>
                    <Route path={`${Constants.ROUTES.meme_detail}:id`} element={<DetailMemeFilePage/>}/>
                    <Route element={<ProtectedRoute/>}>
                        <Route path={Constants.ROUTES.meme_create} element={<CreateMemePage/>}/>
                        <Route path={Constants.ROUTES.tag_create} element={<CreateTagPage/>}/>
                        <Route path={`${Constants.ROUTES.meme_edit}:id`} element={<EditMemePage/>}/>
                        <Route path={`${Constants.ROUTES.profile}:login`} element={<ProfilePage/>}/>
                        <Route path={Constants.ROUTES.profile_edit} element={<EditProfilePage/>}/>
                        <Route path={Constants.ROUTES.my_collection} element={<UserCollectionPage/>}/>
                    </Route>
                    <Route path='*' element={<NotFoundPage/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
