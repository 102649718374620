import {useState} from 'react';

export default function useRefreshToken() {

    const refreshTokenKeyName = 'refresh_token';
    const getRefreshToken = () => {
        return localStorage.getItem(refreshTokenKeyName);
    }
    const [refreshToken, setRefreshToken] = useState(getRefreshToken);

    const saveRefreshToken = userToken => {
        console.log(userToken);
        if (!userToken) {
            localStorage.removeItem(refreshTokenKeyName);
        } else {
            localStorage.setItem(refreshTokenKeyName, JSON.stringify(userToken));
        }
        setRefreshToken(userToken);
    }

    return {
        refreshToken,
        setRefreshToken: saveRefreshToken
    }
}