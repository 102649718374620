import axios, {AxiosError} from "axios";
import {Constants} from "../helpers/Constants";

// const host = 'http://localhost:8080';
const host = process.env.REACT_APP_HOST;

export const MAX_MEMES_FOR_PAGE = 20;


let refresh = true;

export default class Api {

    constructor(auth = false) {
        this.auth = auth;
        refresh = true;
    }

    init = () => {
        let headers = {
            Accept: "application/json"
        };

        if (this.auth) {
            const token = JSON.parse(localStorage.getItem('token'));
            headers.Authorization = `Bearer ${token}`;
        }

        const apiRequester = axios.create({
            baseURL: host,
            headers: headers
        });

        apiRequester.interceptors.response.use(response => {
           return response;
        }, async error => {

            if (error.response.status === 401 && refresh) {
                refresh = false;
                const refreshToken = JSON.parse(localStorage.getItem('refresh_token'));
                try {

                    if (!refreshToken) {
                        if (window.location.pathname !== Constants.ROUTES.signin) {
                            window.location.replace(Constants.ROUTES.signin);
                        }
                        return;
                    }

                    const response = await axios.post(`${host}/api/token/refresh`, {
                        refresh_token: refreshToken
                    });
                    if (response.status === 200) {
                        console.log('set_token');
                        localStorage.setItem('token', JSON.stringify(response.data.token));
                        localStorage.setItem('refresh_token', JSON.stringify(response.data.refresh_token));

                        error.config.headers.Authorization = `Bearer ${response.data.token}`;
                        return axios(error.config);
                    } else {
                        window.location.replace(Constants.ROUTES.signin);
                    }

                } catch (e) {
                    console.log(e);
                    if (window.location.pathname !== Constants.ROUTES.signin) {
                        window.location.replace(Constants.ROUTES.signin);
                    }
                }
            }

            // if (error.response.status === 404) {
            //     window.location.replace(window.location.pathname + '/page/not-found');
            // }

            throw new AxiosError(error.response?.data?.message, error?.response?.status ?? 0);
        });

        return apiRequester;
    };

    getPublicMemes = async ({
        page = 1,
        memeName = null,
        tagIds = [],
        mode = 'strict',
        limit = MAX_MEMES_FOR_PAGE,
        orderBy = 'new'
    }) => {
        console.log('PAGE', page);
        memeName = JSON.stringify(memeName);
        tagIds = JSON.stringify(tagIds);
        const url = `${host}/api/meme/collection/public?mode=${mode}&page=${page}&limit=${limit}&memeName=${memeName}&tagIds=${tagIds}&orderBy=${orderBy}`;
        return this.init().get(url);
    }

    getMemesUser = async ({
      loginUser,
      orderBy,
      page = 1,
      memeName = null,
      tagIds = [],
      mode = 'strict',
      limit = MAX_MEMES_FOR_PAGE,
    }) => {
        memeName = JSON.stringify(memeName);
        tagIds = JSON.stringify(tagIds);
        const url =
            `${host}/api/user/memes?loginUser=${loginUser}&mode=${mode}&page=${page}&limit=${limit}&memeName=${memeName}&tagIds=${tagIds}&orderBy=${orderBy}`;
        return this.init().get(url);
    }

    createMeme = async ({
        file,
        userMemeName,
        tagIds,
        fileLink,
        privateMode,
        memeFileId = null,
        fullCheckSimilarFile = true
    }) => {
        const url = `${host}/api/meme/create`;

        if (fileLink === '') {
            fileLink = null;
        }

        const formData = new FormData();
        formData.append('file', file);
        formData.append('userMemeName', JSON.stringify(userMemeName));
        formData.append('tagIds', JSON.stringify(tagIds));
        formData.append('fileLink', JSON.stringify(fileLink));
        formData.append('privateMode', privateMode);
        formData.append('memeFileId', JSON.stringify(memeFileId));
        formData.append('fullCheckSimilarFile', fullCheckSimilarFile);

        return this.init().post(url, formData);
    }

    editUser = async ({description = null, avatar = null, newPassword = null}) => {
        const url = `${host}/api/user/change`;
        const formData = new FormData();
        formData.append('avatar', avatar);
        formData.append('description', JSON.stringify(description));
        formData.append('newPassword', JSON.stringify(newPassword));
        return this.init().post(url, formData);
    }

    createTag = async (credentials) => {
        const url = `${host}/api/tag/create`;
        return this.init().post(url, credentials);
    }

    deleteTag = async (tagId) => {
        const url = `${host}/api/tags`;
        return this.init().delete(url, {
            data: {
                'tagId': tagId
            }
        });
    }

    editMeme = async (memeId, tagIds, userMemeName, privateMode) => {
        const url = `${host}/api/meme/edit`;

        return this.init().post(url, {
            memeId: memeId,
            userMemeName: userMemeName,
            tagIds: tagIds,
            privateMode: privateMode
        });
    }

    removeMemeById = async (id) => {
        const url = `${host}/api/meme/remove/${id}`;
        return this.init().delete(url);
    }

    getMemeById = async (memeId) => {
        const url = `${host}/api/meme/${memeId}`;
        return this.init().get(url);
    }

    getMemeFileById = async (memeFileId) => {
        const url = `${host}/api/meme/file/${memeFileId}`;
        return this.init().get(url);
    }

    getUserTagsByLogin = async (login) => {
        const url = `${host}/api/tags?login=${login}`;
        return this.init().get(url);
    }

    getTagsByName = async (name) => {
        const url = `${host}/api/tags/${name}`;
        return this.init().get(url);
    }

    login = async (credentials) => {
        const url = `${host}/api/user/login`;
        return this.init().post(url, credentials);
    }

    registration = async (credentials) => {
        const url = `${host}/api/user/registration`;
        return this.init().post(url, credentials);
    }

    // getUserById = async (id) => {
    //     const url = `${host}/api/user/${id}`;
    //     return this.init().get(url);
    // }

    getUserByLogin = async (login) => {
        const url = `${host}/api/user/${login}`;
        return this.init().get(url);
    }

    addFriend = async (friendId) => {
        const url = `${host}/api/user/friends`;
        return this.init().post(url, {
            'friendId': friendId
        });
    }

    sendPity = async (memeFileId) => {
        const url = `${host}/api/pities`;
        return this.init().post(url, {
            'memeFileId': memeFileId
        });
    }

    removeFriend = async (friendId) => {
        const url = `${host}/api/user/friends`;
        return this.init().delete(url, {
            data: {
                'friendId': friendId
            }
        });
    }

    getUserFriends = async (login) => {
        const url = `${host}/api/user/friends/${login}`;
        return this.init().get(url);
    }

    resetPassword = async (email) => {
        const url = `${host}/api/password/reset`;
        return this.init().post(url, {
            'email': email
        })
    }

    restorePassword = async ({password, keyLink}) => {
        const url = `${host}/api/password/restore`;
        return this.init().post(url, {
            'password': password,
            'keyLink': keyLink
        });
    }

    checkResetPasswordKey = async (keyLink) => {
        const url = `${host}/api/password/key-check`;
        return this.init().post(url, {
            'keyLink': keyLink
        })
    }

    updateDescription = async (description) => {
        const url = `${host}/api/user/description`;
        return this.init().post(url, {
            'description': description
        })
    }

    updateAvatar = async (avatarFile) => {
        const url = `${host}/api/user/avatar`;
        const formData = new FormData();
        formData.append('avatar', avatarFile);
        return this.init().post(url, formData);
    }

    updateUserProfile = async ({login=null, description=null, avatarFile=null}) => {
        const url = `${host}/api/user/profile`;
        const formData = new FormData();
        formData.append('login', login);
        formData.append('description', description);
        formData.append('avatarFile', avatarFile);
        return this.init().post(url, formData);
    }

    searchUsers = async (searchLogin) => {
        const url = `${host}/api/user/search/${searchLogin}`;
        return this.init().get(url);
    }

    rateMeme = async ({memeId, mark}) => {
        const url = `${host}/api/meme/rate`;
        return this.init().post(url, {
            memeId: memeId,
            mark: mark
        });
    }

    rateMemeFile = async ({memeFileId, mark}) => {
        const url = `${host}/api/meme/file/rate`;
        return this.init().post(url, {
            memeFileId: memeFileId,
            mark: mark
        });
    }

    addComment = async ({memeFileId, text, memeId=null}) => {
        const url = `${host}/api/meme/comments`;
        return this.init().post(url, {
            memeFileId: memeFileId,
            text: text,
            memeId: memeId
        });
    }

    deleteComment = async (id) => {
        const url = `${host}/api/meme/comments/${id}`;
        return this.init().delete(url);
    }

    getNotifications = async () => {
        const url = `${host}/api/user/notifications`;
        return this.init().get(url);
    }

    deleteNotification = async (id) => {
        const url = `${host}/api/user/notifications/${id}`;
        return this.init().delete(url);
    }

    readNotification = async (id) => {
        const url = `${host}/api/user/notifications/${id}`;
        return this.init().post(url);
    }

    updateFireBaseToken = async (token) => {
        const url = `${host}/api/user/firebase-token`;
        return this.init().post(url, {
            token: token
        });
    }
}
