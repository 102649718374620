import React, {useState} from "react";
import {ReactComponent as OptionsIconSvg} from "../../../assets/images/options.svg";
import './ContextMenuButton.css';
import {Options} from "./Options";

export const ContextMenuButton = (props) => {

    const [showContextMenu, setShowContextMenu] = useState(false);

    return (
        <>
            <button onClick={() => setShowContextMenu(!showContextMenu)} className={'options-button'}>
                <OptionsIconSvg className={showContextMenu ? 'icon-svg-fill' : 'icon-svg-default'}/>
            </button>
            <Options
                options={props.children}
                showContextMenu={showContextMenu}
                setShowContextMenu={setShowContextMenu}
            />
        </>
    );
}