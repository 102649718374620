import React, {useEffect, useState} from "react";
import Api from "../../actions/apiCallHelper";
import 'react-select-search/style.css'
import {Header} from "../../components/Header/Header";
import {toast} from "react-hot-toast";
import './CreateTagPage.css';
import useUser from "../../hooks/useUser";
import {Input} from "../../components/Common/Input/Input";
import {PrivateModeSelector} from "../../components/PrivateModeSelector/PrivateModeSelector";
import {TagPageCollection} from "../../components/TagCollection/TagPageCollection";
import {ModeFilter} from "../../components/Filters/ModeFilter";
import {Constants} from "../../helpers/Constants";
import {ReactComponent as PublicSvg} from '../../assets/images/public.svg'
import {ReactComponent as PrivateSvg} from '../../assets/images/private.svg'
import {ReactComponent as FriendsSvg} from '../../assets/images/friends.svg'
import {ReactComponent as AllOptionsSvg} from '../../assets/images/all-options.svg'

export const CreateTagPage = () => {

    const [tag, setTag] = useState('');
    const [tags, setTags] = useState([]);

    const [filteredTags, setFilteredTags] = useState([]);

    const [searchName, setSearchName] = useState();

    const {user, setUser} = useUser();
    const [privateMode, setPrivateMode] = useState(0);
    const [privateModeFilter, setPrivateModeFilter] = useState(null);

    useEffect(() => {
        const fetchTag = async () => {
            const api = new Api(true);
            try {
                // const idUserTags = user.id;
                const response = await api.getUserTagsByLogin(user.name);
                setTags(response.data);
                setFilteredTags(response.data);
            } catch (e) {
                toast(e.message);
            }
        }
        fetchTag();
    }, []);

    useEffect(() => {

        const filterTagsByPrivateMode = () => {
            if (privateModeFilter === null) {
                return tags;
            }
            return tags.filter((tag) => tag.private === privateModeFilter);
        }

        let filteredTags = filterTagsByPrivateMode();
        console.log(filteredTags);

        if (searchName) {
            filteredTags = filteredTags.filter((tag) => tag.name.toLowerCase().includes(searchName.toLowerCase()));
        }

        setFilteredTags(filteredTags);

    }, [searchName, privateModeFilter, tags])

    const buttonCreateHandler = async () => {
        const api = new Api(true);
        try {
            const response = await api.createTag({
                name: tag,
                privateMode: privateMode
            });
            const createdTag = response.data;
            toast('Тег успешно создан');
            setTag('');
            setTags([createdTag, ...tags]);
        } catch (e) {
            toast(e.message);
        }
    }

    const onChangeSearchByNameHandler = (event) => {
        const name = event.target.value;
        setSearchName(name);
    }

    const deleteHandler = (tagForRemove) => {
        console.log(tagForRemove);
        const api = new Api(true);
        try {
            api.deleteTag(tagForRemove.id);
            toast('Тег удален!');
            setTags(() => tags.filter((t) => t !== tagForRemove));
        } catch (e) {
            toast(e.message);
            console.log(e);
        }
    }


    return (
        <div>
            <Header/>
            <div className='app-global-container'>
                <div className='tag-page-content'>
                    <div className='tag-search-block'>
                        <div className='tag-search'>
                            <span className='header-main'>Поиск по тегам</span>
                            <Input
                                placeholder='Введите название тега для поиска'
                                onChange={(event) => {onChangeSearchByNameHandler(event)}}
                                value={searchName}
                                setValue={setSearchName}
                            />
                        </div>
                        <div className='tag-create'>
                            <div className='tag-create-header'>
                                <span className='header-main'>Новый тег:</span>
                                <PrivateModeSelector setPrivateMode={setPrivateMode} privateMode={privateMode}/>
                            </div>
                            <div className='tag-create-content'>
                                <Input
                                    placeholder='Придумайте название для тега'
                                    onChange={(event) => {
                                        const createdTagName = event.target.value;
                                        setTag(createdTagName);
                                    }}
                                    value={tag}
                                    setValue={setTag}
                                />
                                <button className='tag-create-button' onClick={buttonCreateHandler}>Добавить тег</button>
                            </div>
                        </div>
                    </div>
                    <div className='private-mode-filter-block'>
                        <ModeFilter
                            options={[
                                {
                                    value: null,
                                    text: 'Все теги',
                                    count: tags.length,
                                    icon: AllOptionsSvg
                                },
                                {
                                    value: Constants.PRIVATE_MODE.private,
                                    text: 'Приватные теги',
                                    count: tags.filter((t) => t.private === Constants.PRIVATE_MODE.private).length,
                                    icon: PrivateSvg
                                },
                                {
                                    value: Constants.PRIVATE_MODE.public,
                                    text: 'Публичные теги',
                                    count: tags.filter((t) => t.private === Constants.PRIVATE_MODE.public).length,
                                    icon: PublicSvg
                                },
                                {
                                    value: Constants.PRIVATE_MODE.for_friends,
                                    text: 'Теги для друзей',
                                    count: tags.filter((t) => t.private === Constants.PRIVATE_MODE.for_friends).length,
                                    icon: FriendsSvg
                                }
                            ]}
                            mode={privateModeFilter}
                            setMode={setPrivateModeFilter}
                        />
                    </div>
                    <div className='tag-page-collection-wrapper'>
                        <TagPageCollection tags={filteredTags} deleteHandler={deleteHandler}/>
                    </div>
                </div>
            </div>
        </div>
    );
}