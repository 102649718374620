import './LoginPage.css';
import {Header} from "../../components/Header/Header";
import SignInWindow from "../../components/SignInWindow/SignInWindow";

export const LoginPage = () => {

    return (
        <div>
            <Header/>
            <div className='app-global-container'>
                <SignInWindow/>
            </div>
        </div>
    )
}