import {MAX_MEMES_FOR_PAGE} from "../actions/apiCallHelper";
import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    page: 1,
    tagIds: [],
    mode: 'strict',
    hasMore: true,
    orderBy: 'new',
    limit: MAX_MEMES_FOR_PAGE
}

export const searchGlobalCollectionSlice = createSlice({
    name: 'searchGlobalCollection',
    initialState,
    reducers: {
        setGlobalSearchTagIds: (state, action) => {
            state.tagIds = action.payload.tagIds;
        },
        setGlobalSearchPage: (state, action) => {
            state.page = action.payload.page;
        },
        setGlobalSearchMode: (state, action) => {
            state.mode = action.payload.mode;
        },
        setGlobalSearchOrderBy: (state, action) => {
            state.orderBy = action.payload.orderBy
        },
        setGlobalSearchDefaultSettings: (state) => {
            state.page = 1;
            state.tagIds = [];
            state.mode = 'strict';
            state.limit = MAX_MEMES_FOR_PAGE;
            state.hasMore = true;
        },
        setGlobalSearchHasMore: (state, action) => {
            state.hasMore = action.payload.hasMore;
        }
    },
})

export const {
    setGlobalSearchTagIds,
    setGlobalSearchDefaultSettings,
    setGlobalSearchHasMore,
    setGlobalSearchMode,
    setGlobalSearchOrderBy,
    setGlobalSearchPage
} = searchGlobalCollectionSlice.actions

export default searchGlobalCollectionSlice.reducer