import './SearchUserWindow.css';
import exitSvg from "../../assets/images/exit.svg";
import React, {useEffect, useRef, useState} from "react";
import {Input} from "../Common/Input/Input";
import Api from "../../actions/apiCallHelper";
import {UserRow} from "../User/UserRow";
import {UserRowSkeleton} from "../User/UserRowSkeleton";

export const SearchUserWindow = ({active, setActive}) => {

    const [login, setLogin] = useState('');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const delaySearch = 500;
    const minLengthLoginForSearch = 2;
    const api = new Api();
    const containerRef = useRef();
    let typingTimer;

    useEffect(() => {
        if (login === '' || login === null) {
            setUsers([]);
        }
    }, [login]);

    const onKeyUp = () => {
        clearTimeout(typingTimer);
        typingTimer = setTimeout(fetchUsers, delaySearch);
    }

    const onKeyDown = () => {
        clearTimeout(typingTimer);
    }

    useEffect(() => {
        if (!active) {
            return;
        }

        const handleClick = (e) => {

            if (!containerRef.current) {
                return;
            }

            if (!containerRef.current.contains(e.target)) {
                setActive(false);
            }
        }

        setTimeout(() => document.addEventListener('click', handleClick),0.1);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [active]);

    const onChangeHandler = (e) => {
        setLogin(e.target.value);
    };

    const fetchUsers = async () => {
        if (login.length >= minLengthLoginForSearch) {
            try {
                setLoading(true);
                const response = await api.searchUsers(login);
                setUsers(response.data.users);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        }
    }

    const getSkeleton = () => {
        return (
            <>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
                <UserRowSkeleton/>
            </>
        );
    }

    return (
        <div className='search-user-window-background'>
            <div className='search-user-window-wrapper'>
                <div className='search-user-window' ref={containerRef}>
                    <button className='exit-button no-border-button' onClick={() => setActive(false)}>
                        <img src={exitSvg} alt=''/>
                    </button>
                    <div className='search-user-block'>
                        <span className='search-user-header'>Поиск пользователей</span>
                        <div className='search-user-input'>
                            <Input
                                placeholder='Введите логин пользователя'
                                value={login}
                                setValue={setLogin}
                                onChange={onChangeHandler}
                                onKeyUp={onKeyUp}
                                onKeyDown={onKeyDown}
                            />
                        </div>
                        <span className='search-user-results-str'>Результаты поиска:
                            <span className='search-user-results-str-count'>
                                ({users.length})
                            </span>
                        </span>
                        <div className='search-users-results-users'>
                            { loading ?
                                getSkeleton()
                                :
                                users.map((user) => {
                                    return <UserRow user={user}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
