import React from "react";
import {PrivateModeOption} from "./PrivateModeOption";
import './PrivateModeSelector.css';

export const PrivateModeSelector = ({text, privateMode, setPrivateMode}) => {

    return (
        <div className='private-mode-selector'>
            <PrivateModeOption text='публичный' value={0} currentValue={privateMode} setValue={setPrivateMode}/>
            {' '}/{' '}
            <PrivateModeOption text='для друзей' value={1} currentValue={privateMode} setValue={setPrivateMode}/>
            {' '}/{' '}
            <PrivateModeOption text='приватный' value={2} currentValue={privateMode} setValue={setPrivateMode}/>
        </div>
    );
}