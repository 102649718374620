import React, {useEffect, useRef} from "react";
import {Avatar} from "../../Avatar/Avatar";
import './UserMenu.css';
import {useNavigate} from "react-router-dom";
import {Constants} from "../../../helpers/Constants";
import useAccessToken from "../../../hooks/useAccessToken";
import useRefreshToken from "../../../hooks/useRefreshToken";
import useUser from "../../../hooks/useUser";


export const UserMenu = ({showContextMenu, setShowContextMenu}) => {

    const navigate = useNavigate();

    const {user, setUser} = useUser();

    const {accessToken, setAccessToken} = useAccessToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();

    const containerRef = useRef();

    const redirectToProfile = () => {
        navigate(Constants.ROUTES.profile + user.name);
    }

    const redirectToEditProfile = () => {
        navigate(Constants.ROUTES.profile_edit);
    }

    const exit = () => {
        setAccessToken(false);
        setRefreshToken(false);
        setUser(null);
        navigate(Constants.ROUTES.signin);
    }

    const handleKey = (event) => {
        console.log()
        if (event.key === 'Escape') {
            setShowContextMenu(false);
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handleKey);

        return () => {
            document.removeEventListener("keydown", handleKey);
        }

    }, []);

    useEffect(() => {
        if (!showContextMenu) {
            return;
        }

        const handleClick = (e) => {

            if (!containerRef.current) {
                return;
            }

            if (!containerRef.current.contains(e.target)) {
                setShowContextMenu(false);
            }
        }

        setTimeout(() => document.addEventListener('click', handleClick),0.1);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [showContextMenu]);

    return (
        <div ref={containerRef} className='user-menu-container-wrapper' onKeyDown={handleKey}>
            <div className='user-menu-container'>
                <div className='user-menu-avatar'>
                    <Avatar avatar={user?.avatar} width={72} height={72}/>
                    <span className='user-menu-button'>{user.name}</span>
                    <div className='user-menu-empty-space'></div>
                </div>
                <div className='user-menu-actions'>
                    <span className='user-menu-button' onClick={redirectToProfile}>
                        {'Перейти профиль'}
                    </span>
                    <span className='user-menu-button' onClick={redirectToEditProfile}>
                        {'Редактировать профиль'}
                    </span>
                    <div className='user-menu-empty-space'></div>
                </div>
                <div className={'user-menu-actions'} onClick={exit}>
                    <span className='user-menu-button'>Выйти из профиля</span>
                </div>
            </div>
        </div>
    );
}