export const Constants =  {
    VIDEO_TYPE: 'video',
    IMAGE_TYPE: 'image',
    VIDEO_YOUTUBE_TYPE: 'video-youtube',
    VIDEO_VK_TYPE: 'video-vk',

    STATUS_FRIENDSHIP: {
        FRIEND_WITH_ME: -1,
        NOT_FRIENDS: 0,
        FRIEND: 1,
        APPROVE_FRIEND: 2
    },

    ROUTES: {
        home: '/',
        signin: '/signin',
        signup: '/signup',
        profile: '/profile/',
        profile_edit: '/profile/edit',
        reset_password: '/reset-password/',
        meme_create: '/meme/create',
        meme_edit: '/meme/edit/',
        meme_detail: '/meme/',
        tag_create: '/tag/create',
        my_collection: '/collection/my',
        collection: '/collection/',
    },

    NOTIFICATION: {
        rate_meme: 'rate_meme',
        comment_meme: 'comment_meme',
        new_friend: 'new_friend',
        file_uploaded: 'file_uploaded'
    },

    PRIVATE_MODE: {
        public: 0,
        for_friends: 1,
        private: 2
    }
};