import {useNavigate} from "react-router-dom";
import {Header} from "../../components/Header/Header";
import {Button} from "../../components/Common/Button/Button";
import './NotFoundPage.css';
import {Constants} from "../../helpers/Constants";

export const NotFoundPage = () => {

    const navigate = useNavigate();

    return (
      <>
          <Header/>
          <div className='not-found-content'>
              <div className='not-found-img'>
              </div>
              <span className='not-found-title'>
                  Страница не найдена 404
              </span>
              <span className='not-found-text'>
                  Запрашиваемая вами страница не найдена или не существует
              </span>
              <Button
                  text="Перейти к глобальной коллекции"
                  onClick={() => navigate(Constants.ROUTES.home)}
                  maxWidth={false}
              />
          </div>
      </>
    );
}