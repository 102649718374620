import backArrowSvg from "../../../assets/images/ArrowLeft.svg";
import React from "react";
import {useNavigate} from "react-router-dom";
import './BackButton.css';

export const BackButton = ({text}) => {

    const navigate = useNavigate();

    return (
        <div>
            <button className='back-button' onClick={() => navigate(-1)}>
                <div className='back-button-content'>
                    <img src={backArrowSvg} alt=''/>
                    <span>{text}</span>
                </div>
            </button>
        </div>
    )
}
