import React, {useEffect, useState} from "react";
import {MAX_MEMES_FOR_PAGE} from "../../actions/apiCallHelper";
import {MemeCollection} from "../MemeCollection/MemeCollection";
import {toast} from "react-hot-toast";
import {Header} from "../Header/Header";
import InfiniteScroll from "react-infinite-scroll-component";
import {UserBlock} from "../User/UserBlock";
import {Loader} from "../Common/Loader/Loader";
import {useLocation, useParams} from "react-router-dom";
import {Constants} from "../../helpers/Constants";
import {Search} from "../Search/Search";
import './MainBlock.css';
import {useDispatch} from "react-redux";
import {ScrollToTopButton} from "../ScrollToTopButton/ScrollToTopButton";

export const MainBlock = ({
  searchMemes,
  memes,
  setMemes,
  currPage,
  orderBy,
  memeName,
  tagIds,
  mode,
  hasMore,
  setHasMore,
  setPage,
  setTagIds,
  setMemeName,
  setMode,
  setOrderByState
}) => {

    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();

    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        search();
    }, [currPage, memeName, tagIds, mode, id, orderBy]);

    const search = async () => {
        setIsLoading(true);
        try {
            const response = await searchMemes({currPage, memeName, tagIds, mode, orderBy});
            const dataMemes = response.data.memes;

            if (response.data.isLastPage) {
                dispatch(setHasMore({hasMore: false}));
            }

            if (currPage === 1) {
                setMemes(dataMemes);
            } else {

                if (memesContains(dataMemes[0])) {
                    return;
                }

                setMemes([...memes, ...dataMemes]);
            }
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false);
        }
    }

    const memesContains = (meme) => {
        for (let i=0; i < memes.length; i++) {
            if (meme.id === memes[i].id) {
                return true;
            }
        }

        return false;
    }

    const removeMeme = (memeId) => {
        toast('Мем удален!');

        if (memes.length < MAX_MEMES_FOR_PAGE)  {
            dispatch(setPage({
                page: 1
            }));
        }
    }

    return (
        <>
            <Header/>
            <div className='app-global-container'>
                <div className='main-block-content'>
                    {
                        location.pathname !== Constants.ROUTES.home &&
                        location.pathname !== Constants.ROUTES.my_collection &&
                        <UserBlock/>
                    }
                    <div className='main-block-memes'>
                        <Search
                            tagIds={tagIds}
                            orderByState={orderBy}
                            setPage={setPage}
                            setHasMore={setHasMore}
                            setTagIds={setTagIds}
                            setMemeName={setMemeName}
                            setMode={setMode}
                            setOrderByState={setOrderByState}
                        />
                        <InfiniteScroll
                            dataLength={memes?.length ?? 0} //This is important field to render the next data
                            next={() =>
                                dispatch(setPage({
                                    page: (currPage + 1)
                                }))
                            }
                            hasMore={hasMore}
                            loader={null}
                            endMessage={
                                memes.length > 0 ?
                                    <p style={{textAlign: 'center'}}>
                                        <b>Вы просмотрели всё.</b>
                                    </p> :
                                    location.pathname !== Constants.ROUTES.my_collection ?
                                        <p style={{textAlign: 'center'}}>
                                            <b>Ничего не найдено.</b>
                                        </p> :
                                        <p style={{textAlign: 'center'}}>
                                            <b>Коллекция пуста, добавьте мем</b>
                                        </p>
                            }
                        >
                            <MemeCollection memes={memes} callbackRemove={removeMeme} isLoading={isLoading}
                                            currPage={currPage}/>
                        </InfiniteScroll>
                    </div>
                </div>
                <ScrollToTopButton/>
            </div>
        </>
    );
}