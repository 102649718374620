import {Tag} from "../Tag/Tag";
import './TagCollection.css';
import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setUserSearchDefaultSettings, setUserSearchTagIds} from "../../store/searchUserCollectionSlice";
import {setGlobalCollection, setGlobalTags, setUserCollection, setUserTags} from "../../store/memesCollectionSlice";
import {Constants} from "../../helpers/Constants";
import {MemeCardGlobal} from "../MemeCard/MemeCardGlobal";
import {MemeCard} from "../MemeCard/MemeCard";
import {setGlobalSearchDefaultSettings, setGlobalSearchTagIds} from "../../store/searchGlobalCollectionSlice";
import {useLocation} from "react-router-dom";

export const TagCollection = ({tags = []}) => {

    let ref = useRef(null);

    const chosenUserTags = useSelector((state) => state.memesCollection.userTags);

    const dispatch = useDispatch();

    const location = useLocation();

    const [state, setState] = useState({
       isScrolling: false,
        clientX: 0,
        scrollX: 0
    });

    useEffect(() => {
        const el = ref.current;

        const areaListener = new AbortController();

        if (el) {
            const onWheel = e => {
                e.preventDefault();
                el.scrollTo({
                    left: el.scrollLeft + e.deltaY * 0.4,
                    behaviour: 'smooth'
                })
            }
            el.addEventListener('wheel', onWheel, {signal: areaListener.signal});

            el.addEventListener('mousedown', onMouseDown, {signal: areaListener.signal});
            el.addEventListener('mouseup', onMouseUp, {signal: areaListener.signal});
            el.addEventListener('mousemove', onMouseMove, {signal: areaListener.signal});
        }

        return () => {
            areaListener.abort();
        }

    }, []);

    const onMouseDown = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            return;
        }
        e.preventDefault();
        setState({
            ...state,
            isScrolling: true,
            clientX: e.clientX
        })
    }

    const onMouseUp = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            return;
        }
        e.preventDefault();
        setState({
            ...state,
            isScrolling: false
        })
    }

    const onMouseMove = (e) => {
        if (ref && ref.current && !ref.current.contains(e.target)) {
            return;
        }
        e.preventDefault();
        const {clientX, scrollX, isScrolling} = state;

        if (isScrolling) {
            ref.current.scrollLeft = scrollX + e.clientX - clientX;
        }

        setState({
            ...state,
            scrollX: scrollX + e.clientX - clientX,
            clientX: e.clientX
        })
    }
    // const CardMeme = location.pathname === Constants.ROUTES.home ? MemeCardGlobal : MemeCard;
    const callback = location.pathname === Constants.ROUTES.my_collection ?
        (tag) => {
        dispatch(setUserSearchDefaultSettings());
        dispatch(setUserCollection([]))
        dispatch(setUserSearchTagIds({
            tagIds: [tag.id]
        }));
        dispatch(setUserTags([tag]));
    } : null;

    return (
        <div
            ref={ref}
            className='tags'
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
        >
            {tags.length ? tags.map(tag => (
                <Tag tag={tag} key={tag.id} callback={callback}/>
            )) : ''}
        </div>
    );
}