import './MemeCard.css';
import {useRef, useState} from "react";
import './MemeCardDetail.css';
import {TagCollection} from "../TagCollection/TagCollection";
import {MemeContent} from "./MemeContent";
import {MarkButtons} from "./MarkButtons";
import {TagPlug} from "../TagPlug/TagPlug";
import {DisplayTypeMeme} from "../DisplayTypeMeme/DisplayTypeMeme";
import {OwnerCollection} from "../Owner/OwnerCollection";
import {CardCreator} from "./CardCreator";
import {Constants} from "../../helpers/Constants";
import {useNavigate} from "react-router-dom";

export const MemeCardDetail = ({meme: memeInit}) => {

    const [meme, setMeme] = useState(memeInit);
    const memeContentContainerRef = useRef();
    const navigate = useNavigate();

    return (
        <div className='meme-card-detail'>
            <div>
                {meme.memeFile === undefined ?
                    <OwnerCollection owners={meme.owners} meme={meme}/>
                    :
                    <CardCreator meme={meme} callbackRemove={() => navigate(Constants.ROUTES.my_collection)}/>
                }
            </div>
            <div className='tags'>
                {meme.tags.length === 0 ?
                    <TagPlug/> :
                    <TagCollection tags={meme.tags}/>
                }
            </div>
            {meme.userMemeName &&
                <span className='meme-card-name'>
                    {meme.userMemeName}
                </span>
            }
            <div className='content' ref={memeContentContainerRef}>
                <MemeContent
                    memeFile={meme.memeFile === undefined ? meme : meme.memeFile}
                    width={743}
                    height={480}
                    containerRef={memeContentContainerRef}
                />
            </div>
            <div className='btn-keeper'>
                <MarkButtons meme={meme} setMeme={setMeme}/>
                <DisplayTypeMeme meme={meme.memeFile === undefined ? {privateMode: Constants.PRIVATE_MODE.public} : meme}/>
            </div>
        </div>
    );
}