import './Comment.css';
import {Avatar} from "../Avatar/Avatar";
import {ReactComponent as PrivateSvg} from '../../assets/images/private.svg'
import {useState} from "react";
import useUser from "../../hooks/useUser";

export const Comment = ({comment, deleteComment}) => {

    const {user, setUser} = useUser();
    const rawDate = Date.parse(comment.createdAt.date);
    const dateObj = new Date(rawDate);
    const commentCreatedAtDate = dateObj.toLocaleString('ru-Ru', {
        month: "2-digit",
        year: "numeric",
        day: "2-digit",
        hour: '2-digit',
        minute: '2-digit'
    });

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const onMouseOverHandler = () => {
        if (user?.id === comment.commentator.id) {
            setShowDeleteButton(true);
        }
    };

    const onMouseOutHandler = () => {
        setShowDeleteButton(false);
    };

    return (
        <div
            className='meme-comment'
            onMouseOver={onMouseOverHandler}
            onMouseOut={onMouseOutHandler}
        >
            <div className='comment-container'>
                <div className='comment-user-part'>
                    <div className='comment-user-name'>
                        <Avatar avatar={comment.commentator?.avatar}/>
                        {comment.commentator?.name}
                    </div>
                    <div className='comment-date'>
                        {showDeleteButton && <div className='comment-delete-button-link' onClick={() => deleteComment(comment.id)}>Удалить</div>}
                        {comment.memeId !== null && <PrivateSvg/>}
                        {commentCreatedAtDate}
                    </div>
                </div>
                <div className='comment-content'>
                    {comment.text}
                </div>
            </div>
        </div>
    );
}