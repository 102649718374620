import {useDispatch, useSelector} from "react-redux";
import {setUserGlobal} from "../store/userSlice";

export default function useUser() {

    const user = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const saveUser = (userData) => {
        dispatch(setUserGlobal({userData: userData}));
    }

    return {
        user: !user.id ? null : user,
        setUser: saveUser,
    }
}