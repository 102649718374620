import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header/Header";
import './EditMemePage.css';
import {EditMemeWindow} from "../../components/EditMemeWindow/EditMemeWindow";
import {BackButton} from "../../components/Common/BackButton/BackButton";

export const EditMemePage = () => {

    return (
        <div>
            <Header/>
            <div className='main-block-content'>
                <div className='edit-meme-content-wrapper'>
                    <div className='edit-meme-content'>
                        <BackButton text='Выйти из редактирования'/>
                        <EditMemeWindow/>
                    </div>
                </div>
            </div>
        </div>
    );
}