import './SelectedTags.css';
import {Tag} from "../Tag/Tag";
import React, {useEffect, useRef, useState} from "react";
import {Constants} from "../../helpers/Constants";
import {setUserCollection} from "../../store/memesCollectionSlice";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";

export const SelectedTags = ({selectedTags, setSelectedTags}) => {

    let refSelectedTags = useRef(null);

    const location = useLocation();
    const dispatch = useDispatch();

    let drag = false;
    let scrollLeft = 0; // отпустили мышку - сохраняем положение скролла
    let startX = 0; // нажали мышку - сохраняем координаты.


    useEffect(() => {
        const el = refSelectedTags.current;
        if (el) {
            el.addEventListener('mousedown', function(e) {
                drag = true;
                el.classList.add('grabbable');
                scrollLeft = el.offsetLeft;
                startX = e.pageX - el.offsetLeft;
            });

            el.addEventListener('mouseleave', function(e) {
                drag = false;
                el.classList.remove('grabbable');
            });

            el.addEventListener('mouseup', function(e) {
                drag = false;
                el.classList.remove('grabbable');
            });

            el.addEventListener('mousemove', function(e) {
                // console.log('mouseMove', drag);
                if (!drag) return;
                e.preventDefault();
                const x = e.pageX - el.offsetLeft;
                const walk = x - startX;
                el.scrollLeft += walk * 0.1;
            });
        }
    }, []);

    return (
        <div className='selected-tags' ref={refSelectedTags}>
            {selectedTags.map(tag => {
                return <Tag tag={tag} id={tag.id} deleteable={true} callback={
                    () => {
                        setSelectedTags(selectedTags.filter(t => t.id !== tag.id));
                        if (location.pathname === Constants.ROUTES.my_collection) {
                            dispatch(setUserCollection([]));
                        }
                    }
                }/>
            })}
        </div>
    )
}
