import {Avatar} from "../Avatar/Avatar";
import './OwnerCollection.css';
import {AvatarPlug} from "../Avatar/AvatarPlug";
import {copyMemeImg, shareMeme} from "../../helpers/memeHelper";
import copyMemeSvg from "../../assets/images/copy-meme.svg";
import shareMemeSvg from "../../assets/images/share.svg";
import {ContextMenuButton} from "../Common/ContextMenu/ContextMenuButton";
import {ContextMenuOption} from "../Common/ContextMenu/ContextMenuOption";
import {UserRow} from "../User/UserRow";
import pitySvg from "../../assets/images/pity.svg";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import useUser from "../../hooks/useUser";
import {useState} from "react";

export const OwnerCollection = ({owners, meme}) => {

    const {user, setUser} = useUser();
    const [canSendPity, setCanSendPity] = useState(true);

    console.log(owners);

    const ownersView = () => {
        const result = [];

        const shift = 16;
        const maxCountForView = 3;

        const ownersLength = owners.length;
        for (let i = 1; i < ownersLength; i++) {

            if (ownersLength > (maxCountForView + 1) && i >= maxCountForView) {
                result.push(
                    <div className={'zam'} style={{
                        left: (i * shift)+'px',
                     }}>
                        <AvatarPlug count={ownersLength - i}/>
                    </div>
                );
                break;
            }

            result.push(
                <div
                    key={owners[i].id}
                    className={'zam'} style={{
                    left: (i * shift)+'px',
                }}>
                    <Avatar avatar={owners[i]?.avatar}/>
                </div>
            );
        }

        return result;
    }

    const sendPity = async (memeFileId) => {
        const api = new Api(user);
        try {
            await api.sendPity(memeFileId);
            toast('Жалоба отправлена');
            setCanSendPity(false);
        } catch (e) {
            if (e.code === 400) {
                setCanSendPity(false);
                toast(e.message);
            }
            console.log(e);
        }
    }

    return (
        <div className='card-owners'>
            <div className='card-owners-user-rows'>
                <UserRow user={owners[0]}/>
                <div className='card-owners-other-owners'>
                    {ownersView()}
                </div>
                <ContextMenuButton>
                    <ContextMenuOption
                        handler={() => copyMemeImg(meme)}
                        imgSrc={copyMemeSvg}
                        alt={'copy meme'}
                        text="Скопировать мем"
                    />
                    <ContextMenuOption
                        handler={() => shareMeme(meme.id)}
                        imgSrc={shareMemeSvg}
                        alt={'share meme'}
                        text="Поделиться"
                    />
                    {canSendPity && ((!user || !(owners.map(owner => owner.id)).includes(user?.id))) &&
                        <ContextMenuOption
                            handler={() => sendPity(meme.id)}
                            imgSrc={pitySvg}
                            alt={'send pity'}
                            text="Жалоба"
                        />
                    }
                </ContextMenuButton>
            </div>
        </div>
    );
}