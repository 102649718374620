import './AvatarPlug.css';

export const AvatarPlug = ({count}) => {

    if (count > 999) {
        count = 999;
    }

    return (
        <div className='avatar-plug'>
            {'+' + count}
        </div>
    );
}