import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Api from "../../actions/apiCallHelper";
import {Constants} from "../../helpers/Constants";
import {toast} from "react-hot-toast";
import {Input} from "../Common/Input/Input";
import {Button} from "../Common/Button/Button";
import './SignUpWindow.css';
import jwtDecode from "jwt-decode";
import useUser from "../../hooks/useUser";
import useAccessToken from "../../hooks/useAccessToken";
import useRefreshToken from "../../hooks/useRefreshToken";

function SignUpWindow() {

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [login, setLogin] = useState('');

    const [password, setPassword] = useState('');
    const [passwordAgain, setPasswordAgain] = useState('');

    const [errorMsg, setErrorMsg] = useState('');
    const [isErrorPassword, setIsErrorPassword] = useState(false);

    const [isErrorEmail, setIsErrorEmail] = useState(false);
    const [errorMsgEmail, setErrorMsgEmail] = useState(null);

    const {user, setUser} = useUser();
    const {accessToken, setAccessToken} = useAccessToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();

    useEffect(() => {

        const changePassword = () => {
            if (password === '') {
                setErrorMsg(null);
                setIsErrorPassword(false);
            } else if (password !== passwordAgain) {
                setErrorMsg('Пароли не совпадают!');
                setIsErrorPassword(true);
            } else {
                setErrorMsg(null);
                setIsErrorPassword(false);
            }
        }
        changePassword();
    }, [passwordAgain]);

    useEffect(() => {
        if (email === '' || email === null) {
            setIsErrorEmail(false);
            setErrorMsgEmail(null);
        } else if (!isCorrectEmail()) {
            setIsErrorEmail(true);
            setErrorMsgEmail('Некорректный email');
        } else {
            setIsErrorEmail(false);
            setErrorMsgEmail(null);
        }
    }, [email]);

    const loginUser = async ({data}) => {
        setAccessToken(data?.token);
        setRefreshToken(data?.refresh_token);
        const tokenData = jwtDecode(data?.token);
        setUser(tokenData);
        navigate(Constants.ROUTES.my_collection);
    }

    const handleSubmit = async e => {
        e.preventDefault();

        const api = new Api();

        if (password !== passwordAgain) {
            setErrorMsg('Пароли не совпадают!');
            return;
        }

        try {
            const response = await api.registration({
                'email': email,
                'login': login,
                'password': password
            });
            toast('Пользователь зарегистрирован!');
            setPassword('');
            setPasswordAgain('');
            await loginUser(response);
        } catch (e) {
            toast(e.message);
        }
    }

    const isCorrectEmail = () => {
        const re = /^(?:[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&amp;'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
        return re.test(email);
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='login-window'>
                <span className='signin-header'>Регистрация</span>
                <div className='signup-inputs'>
                    <Input
                        placeholder='Электронная почта'
                        value={email}
                        setValue={setEmail}
                        onChange={e => setEmail(e.target.value)}
                        type='email'
                        isError={isErrorEmail}
                        errorMsg={errorMsgEmail}
                        autocomplete='email'
                    />
                    <Input
                        placeholder='Логин'
                        value={login}
                        setValue={setLogin}
                        onChange={e => setLogin(e.target.value)}
                        autocomplete='username'
                    />
                    <Input
                        placeholder='Пароль'
                        type='password'
                        value={password}
                        setValue={setPassword}
                        onChange={e => setPassword(e.target.value)}
                    />
                    <Input
                        placeholder='Повторите пароль'
                        type='password'
                        value={passwordAgain}
                        setValue={setPasswordAgain}
                        onChange={e => setPasswordAgain(e.target.value)}
                        errorMsg={errorMsg}
                        isError={isErrorPassword}
                    />
                </div>
                <Button text='Зарегистрироваться' submit={true}/>
                <div className='signup-link-container'>
                    <span>
                        Уже есть аккаунт?
                    </span>
                    <div className='underline-link-button' onClick={() => navigate(Constants.ROUTES.signin)}>
                        Войти
                    </div>
                </div>
            </form>
        </>
    );
}

export default SignUpWindow;