import {Constants} from "../../helpers/Constants";
import {useEffect, useRef, useState} from "react";
import {TagCollection} from "../TagCollection/TagCollection";
import {MemeContent} from "./MemeContent";
import {OwnerCollection} from "../Owner/OwnerCollection";
import {ReactComponent as CommentSvg} from '../../assets/images/comments.svg';
import {Link, useNavigate} from "react-router-dom";
import {MarkButtons} from "./MarkButtons";
import {TagPlug} from "../TagPlug/TagPlug";

export const MemeCardGlobal = ({meme: memeInit}) => {

    const [meme, setMeme] = useState(memeInit);
    const navigate = useNavigate();

    const memeContentContainerRef = useRef();

    useEffect(() => {
        setMeme(memeInit);
    }, [memeInit]);

    return (
        <div>
            <div className='card'>
                <OwnerCollection owners={meme.owners} meme={meme}/>
                {meme.tags.length === 0 ?
                    <div className='tags'>
                        <TagPlug/>
                    </div>
                    :
                    <TagCollection tags={meme.tags}/>
                }
                <Link to={Constants.ROUTES.meme_detail + meme.id} ref={memeContentContainerRef} className='card-body' state={{meme: meme}}>
                    <MemeContent
                        memeFile={meme}
                        width={298}
                        height={320}
                        containerRef={memeContentContainerRef}
                    />
                </Link>
                <div className='btn-keeper'>
                    {!meme.temp &&
                        <>
                            <MarkButtons meme={meme} setMeme={setMeme}/>
                            <div className='comment-button-wrapper'>
                                <button className='comment-button' onClick={() => navigate(Constants.ROUTES.meme_detail + meme.id)}>
                                    <CommentSvg className='comment-svg'/>
                                </button>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}