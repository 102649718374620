import './ContextMenuOption.css';

export const ContextMenuOption = ({handler, text='', imgSrc=null, alt=''}) => {

    return (
        <button onClick={handler} className='context-menu-option-button'>
            <div className='context-menu-option-content'>
                {imgSrc && <img src={imgSrc} alt={alt}/>}
                {text && <span>{text}</span>}
            </div>
        </button>
    );
}