import FriendsHelper from "../../helpers/friendsHelper";
import './FriendCard.css';
import {Avatar} from "../Avatar/Avatar";
import {useNavigate, useParams} from "react-router-dom";
import {Constants} from "../../helpers/Constants";
import {useState} from "react";
import useUser from "../../hooks/useUser";
import {scrollToTop} from "../../helpers/common";
import {setUserSearchDefaultSettings} from "../../store/searchUserCollectionSlice";
import {setUserCollection, setUserTags} from "../../store/memesCollectionSlice";
import {useDispatch} from "react-redux";

export const FriendCard = ({friend, status, statusCurrentUser, updateFriendCallback}) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [currentFriend, setCurrentFriend] = useState(friend);

    const friendsHelper = new FriendsHelper();

    const {login} = useParams();

    const {user, setUser} = useUser();

    const getShortDescription = (description) => {
        if (description) {
            const maxlength = 40;
            if (description.length < maxlength) {
                return description;
            } else {
                return description.substring(0, maxlength) + '...';
            }
        }

        return '';
    }

    const updateStatusFriend = (newStatus) => {
        if (login === user.name) {
            console.log('t');
            updateFriendCallback({...currentFriend, status: newStatus, statusCurrentUser: newStatus});
        } else {
            updateFriendCallback({...currentFriend, status: status, statusCurrentUser: newStatus});
        }
    }

    const onUserClick = () => {
        scrollToTop(() => {
            dispatch(setUserSearchDefaultSettings());
            dispatch(setUserCollection([]));
            dispatch(setUserTags([]));
            navigate(Constants.ROUTES.collection + currentFriend.name);
        })
    }

    return (
        <div className='friend-card'>
            <Avatar avatar={currentFriend?.avatar} width={96} height={96}/>
            <div className='friend-card-content'>
                <div className='friend-card-user-row'>
                    <span className='pointer' onClick={onUserClick}>
                        {currentFriend.name}
                    </span>
                    <span>
                        {friendsHelper.convertStatus(statusCurrentUser)}
                    </span>
                </div>
                <div className='friend-card-user-description'>
                    <span>{getShortDescription(currentFriend?.description)}</span>
                </div>
                {user.name !== currentFriend.name &&
                    <div className='friend-card-action-button'>
                    {friendsHelper.getButtonActionFriend(statusCurrentUser, currentFriend.id, updateStatusFriend)}
                    </div>
                }
            </div>
        </div>
    );
}