import './Tag.css';
import {Constants} from "../../helpers/Constants";
import {ReactComponent as ExitSvg} from '../../assets/images/exit.svg';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";

export const Tag = ({tag, className=null, callback=null, deleteable = false}) => {

    const location = useLocation();
    const getStyleForTag = () => {
        switch (tag.private) {
            case Constants.PRIVATE_MODE.for_friends:
                return 'tag-for-friends';
            case Constants.PRIVATE_MODE.private:
                return 'tag-private';
            default:
                return 'tag-default';
        }
    }

    const getClassName = () => {
        let classNameTag = 'tag ' + getStyleForTag();

        if (location.pathname === Constants.ROUTES.my_collection) { // т.к на странице своей коллекции можно сделать так, чтобы он добавлялся в поиск автоматом
            classNameTag += ' pointer';
        }

        if (className !== null) {
            classNameTag += ' ' + className;
        }

        return classNameTag;
    }

    return (
        <div className={getClassName()} onClick={() => {
            if (callback !== null && deleteable === false) {
                callback(tag);
            }
        }}>
            <div className='tag-value'>
                <div>
                    {tag.name}
                </div>
                {deleteable &&
                    <div onClick={() => callback(tag)} className='tag-delete pointer'>
                        <ExitSvg/>
                    </div>
                }
            </div>
        </div>
    );
}