import Api from "../../actions/apiCallHelper";
import React, {useEffect, useState} from "react";
import Select from 'react-select';
import './Search.css';
import {toast} from "react-hot-toast";
import AsyncSelect from "react-select/async";
import {useLocation, useParams} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {useDispatch, useSelector} from "react-redux";
import {Tag} from "../Tag/Tag";
import {getMessage} from "../../helpers/messageHelper";
import {Messages} from "../../helpers/messages";
import {ModeFilter} from "../Filters/ModeFilter";
import {ReactComponent as StarSvg} from "../../assets/images/public.svg";
import {ReactComponent as PopularSvg} from "../../assets/images/popular.svg";
import {SearchInput} from "../Common/SearchInput/SearchInput";
import {Constants} from "../../helpers/Constants";
import {setGlobalTags, setUserTags} from "../../store/memesCollectionSlice";
import {MySelectTagsComponent} from "../CreatableSelectTags/MySelectTagsComponent";

export const Search = ({
   orderByState,
   setPage,
   setHasMore,
   setTagIds,
   setMemeName,
   setMode,
   setOrderByState
}) => {

    const [search, setSearch] = useState('');
    const [tags, setTags] = useState([]);

    const chosenUserTags = useSelector((state) => state.memesCollection.userTags);
    const chosenGlobalTags = useSelector((state) => state.memesCollection.globalTags);

    const [searchMode, setSearchMode] = useState(true);
    const [orderBy, setOrderBy] = useState(orderByState);

    const [isChange, setIsChange] = useState(false);

    const location = useLocation();

    const {user, setUser} = useUser();
    const {login} = useParams();

    const dispatch = useDispatch();

    const filter = () => {
        let userMemeNameSearch = null;
        if (!(search.trim() === '')) {
            userMemeNameSearch = search;
        }

        const currentPageTags = location.pathname === Constants.ROUTES.home ? chosenGlobalTags : chosenUserTags;

        let tagIds = currentPageTags.map((tag) => {
            return location.pathname === Constants.ROUTES.home ? tag.value : tag.id; // TODO изменить когда будет свой компонент поиска
        });

        if (isChange) {
            dispatch(setPage({
                page: 1
            }));
            dispatch(setHasMore({
                hasMore: true
            }));
        }

        dispatch(setTagIds({
            tagIds: tagIds
        }))

        if (setMemeName) {
            dispatch(setMemeName({
                memeName: userMemeNameSearch
            }));
        }

        if (searchMode) {
            dispatch(setMode({
                mode: 'strict'
            }));
        } else {
            dispatch(setMode({
                mode: 'no_strict'
            }));
        }

        setIsChange(false);
    }

    useEffect(() => {
        const fetchTag = async () => {

            if (location.pathname !== Constants.ROUTES.home) {
                try {
                    const api = new Api(user);
                    const userLogin = login ?? user?.name;
                    const response = await api.getUserTagsByLogin(userLogin);
                    setTags(response.data);
                } catch (e) {
                    toast(e.message);
                }
            }
        }
        fetchTag();
    }, [login]);

    const onChangeSelectHandler = (selectedTags) => {
        if (location.pathname === Constants.ROUTES.home) {
            dispatch(setGlobalTags(selectedTags))
        } else {
            dispatch(setUserTags(selectedTags));
        }
        setIsChange(true);
    }

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            const api = new Api();
            setTimeout(async () => {
                const response = await api.getTagsByName(inputValue);
                const tags = response.data.tags.map(tag => {
                    return {value: tag.id, label: tag.name, privateMode: tag.private};
                });
                console.log(tags);
                resolve(tags);
            }, 1000);
        });

    const formatOptionLabel = ({value, label, privateMode}) => {
        return <>
            <Tag tag={{
                id: value,
                name: label,
                private: privateMode
            }}/>
        </>
    };

    const customStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: 48,
            background: '#FCFCFC',
            border:  state.isFocused ? '1px solid #946BFF' : '0',
            borderRadius: '4px',
            boxShadow: '0',
        })
    };

    const widthTagSearch = location.pathname === Constants.ROUTES.home ? 1014 : 437;

    return (
        <div className='search'>
            {location.pathname !== Constants.ROUTES.home &&
                <div className="search-by-name">
                    <span className='header-main'>Поиск по названию</span>
                    <form className={'search-form'} onSubmit={(e) => {
                        e.preventDefault();
                        filter();
                    }}>
                        <SearchInput
                            onChange={(e) => {
                                setSearch(e.target.value)
                                setIsChange(true);
                            }}
                            handleKey={filter}
                            placeholder='Введите название для поиска'
                        />
                    </form>
                </div>
            }
            <div className='search-by-tags' style={{width: `${widthTagSearch}px`}}>
                <span className='header-main'>Поиск по тегам</span>
                <div className='tags-selector'>
                    {location.pathname === Constants.ROUTES.home ?
                        <AsyncSelect
                            loadOptions={promiseOptions}
                            cacheOptions
                            placeholder='Введите название тега'
                            loadingMessage={() => 'Ищем теги...'}
                            noOptionsMessage={() => 'Ничего не найдено'}
                            isMulti
                            onChange={elements => onChangeSelectHandler(elements)}
                            formatOptionLabel={formatOptionLabel}
                            styles={customStyles}
                            closeMenuOnSelect={false}
                            onMenuClose={() => filter()}
                            defaultValue={chosenGlobalTags}
                        /> :
                        <MySelectTagsComponent
                            tags={tags}
                            setTags={setTags}
                            selectedTags={chosenUserTags}
                            setSelectedTags={onChangeSelectHandler}
                            filter={filter}
                            creatable={false}
                        />
                    }
                </div>
            </div>
            <div className='search-order-by'>
                <div className='search-order-by-title'>
                    {getMessage(Messages.SHOW_FIRST)}
                </div>
                <ModeFilter
                    options={[
                        {
                            value: 'new',
                            text: 'Новинки',
                            icon: StarSvg
                        },
                        {
                            value: 'rating',
                            text: 'Популярные',
                            icon: PopularSvg
                        },
                    ]}
                    mode={orderBy}
                    setMode={setOrderBy}
                    extraAction={(valueOrderBy) => {
                        dispatch(setPage({
                            page: 1
                        }));
                        dispatch(setHasMore({
                            hasMore: true
                        }));
                        dispatch(setOrderByState({
                            orderBy: valueOrderBy
                        }));
                    }}
                />
            </div>
        </div>
    );
}