import './Header.css';
import {useLocation, useNavigate} from "react-router-dom";
import React from "react";
import {scrollToTop} from "../../helpers/common";
import {useDispatch} from "react-redux";

export const HeaderButton = ({text, path, disabled=false, withBorder = true}) => {

    const location = useLocation();
    const navigate = useNavigate();
    let className = 'btn-header';

    if (location.pathname === path) {
        withBorder ? className += ' active-btn-border' : className += ' active-btn';
    }

    const scrollToTopAndRedirect = (redirectUrl) => {
        scrollToTop(() => {
            navigate(redirectUrl);
        });
    }

    return (
        <>
            <button disabled={disabled} className={className} onClick={() => {scrollToTopAndRedirect(path)}}>
                {text}
            </button>
        </>
    );
}