import React, {useEffect, useState} from 'react';
import useUser from "../../hooks/useUser";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import {PrivateModeSelector} from "../PrivateModeSelector/PrivateModeSelector";
import {CreatableSelectTags} from "../CreatableSelectTags/CreatableSelectTags";
import {Button} from "../Common/Button/Button";
import {MemeCard} from "../MemeCard/MemeCard";
import {Input} from "../Common/Input/Input";
import checkMarkSvg from '../../assets/images/check_mark.svg';
import exitSvg from '../../assets/images/exit.svg';
import './CreateMemeWindow.css';
import {Constants} from "../../helpers/Constants";
import {useDispatch} from "react-redux";
import {addMemeToUserCollection, setGlobalCollection} from "../../store/memesCollectionSlice";
import {setGlobalSearchDefaultSettings} from "../../store/searchGlobalCollectionSlice";
import {ChooseFromSimilarMemesWindow} from "./ChooseFromSimilarMemes/ChooseFromSimilarMemesWindow";
import {MemeCardSkeleton} from "../MemeCard/MemeCardSceleton";
import {MySelectTagsComponent} from "../CreatableSelectTags/MySelectTagsComponent";

function CreateMemeWindow() {

    const [file, setFile] = useState();
    const [userMemeName, setUserMemeName] = useState('');
    const [fileLink, setFileLink] = useState('');

    const [tags, setTags] = useState([]);

    const [chosenTags, setChosenTags] = useState([]);
    const [privateMode, setPrivateMode] = useState(0);
    const [tempUrl, setTempUrl] = useState(null);
    const {user, setUser} = useUser();
    const dispatch = useDispatch();

    const [showWindowHasSimilarMeme, setShowWindowHasSimilarMeme] = useState(false);

    const [similarMemeFile, setSimilarMemeFile] = useState(null);
    const [loading, setLoading] = useState(false);

    const [meme, setMeme] = useState({
        memeFile: {
            id: 0,
            type: 'image',
            fileLink: tempUrl
        },
        owner: {
            id: user.id,
            name: user.name,
            avatar: user.avatar
        },
        privateMode: privateMode,
        tags: chosenTags,
        id: 0,
        mark: null,
        temp: true,
    });

    useEffect(() => {

        let type = Constants.IMAGE_TYPE;

        if (file?.type?.includes(Constants.VIDEO_TYPE)) {
            type = Constants.VIDEO_TYPE;
        }

        let externalUrl = null;

        if (fileLink?.startsWith('https://www.youtube.com/watch?v=')) {
            type = Constants.VIDEO_YOUTUBE_TYPE;
            const idVideo = fileLink.split('=').pop();
            externalUrl = 'https://www.youtube.com/embed/' + idVideo;
        } else if (fileLink?.startsWith('https://vk.com/video')) {
            type = Constants.VIDEO_VK_TYPE;
        } else if (fileLink) {
            externalUrl = fileLink;
        }

        setMeme({
            memeFile: {
                id: 0,
                type: type,
                fileLink: externalUrl ?? tempUrl
            },
            owner: {
                id: user.id,
                name: user.name,
                avatar: user.avatar
            },
            privateMode: privateMode,
            tags: chosenTags,
            id: 0,
            mark: null,
            temp: true,
            userMemeName: userMemeName
        })
    }, [privateMode, tempUrl, chosenTags, userMemeName, user, file, fileLink, userMemeName]);


    useEffect(() => {
        const fetchTag = async () => {
            const api = new Api(true);
            try {
                const response = await api.getUserTagsByLogin(user.name);
                setTags(response.data);
            } catch (e) {
                toast(e.message);
            }
        }
        fetchTag();
    }, []);

    const chooseFileHandler = (event) => {
        const file = event.target.files[0];
        setFile(file);

        const url = URL.createObjectURL(file);
        setTempUrl(url);
    }

    const changeUserMemeNameInputHandler = (event) => {
        const name = event.target.value;
        setUserMemeName(name);
    }

    const changeFileLink = (event) => {
        const link = event.target.value;
        setFileLink(link);
    }

    const createMemeHandler = async (e, fullCheckSimilarFile = true, memeFileId = null) => {
        e.preventDefault();

        const chosenTagIds = chosenTags.map(t => {
            return t.id;
        });

        setLoading(true);

        const api = new Api(true);
        try {
            let response = await api.createMeme({
                file,
                userMemeName,
                tagIds: chosenTagIds,
                fileLink,
                privateMode,
                memeFileId: memeFileId,
                fullCheckSimilarFile: fullCheckSimilarFile
            });
            response = response.data;

            if (response.status === 'CREATE_OK') {
                dispatch(addMemeToUserCollection(response.meme));
                dispatch(setGlobalCollection([]));
                dispatch(setGlobalSearchDefaultSettings());
                setMeme(response.meme)
                toast('Мем создан!');
                setShowWindowHasSimilarMeme(false);
            } else {
                setShowWindowHasSimilarMeme(true);
                setSimilarMemeFile(response.memeFile)
            }

        } catch (e) {
            console.log(e);
            toast(e.message);
        } finally {
            setLoading(false);
        }
    }

    const callbackRemove = () => {
        setTempUrl(null);
    }

    const fileNamePreview = (name) => {
        if (name.length < 30) {
            return name;
        }

        return name.slice(0, 30) + '...';
    }

    return (
        <div className='create-meme-window-wrapper'>
            {loading ? <MemeCardSkeleton/> : <MemeCard meme={meme} callbackRemove={callbackRemove}/>}
            <div className='create-meme-window'>
                <div className='create-meme-title'>
                    <span>Добавить мем</span>
                </div>
                <PrivateModeSelector
                    privateMode={privateMode}
                    setPrivateMode={setPrivateMode}
                    text="Выберите модификатор доступа для мема:"
                />
                <div className='form-create-meme'>
                    <Input
                        placeholder='Введите имя мема'
                        onChange={event => changeUserMemeNameInputHandler(event)}
                        value={userMemeName}
                        setValue={setUserMemeName}
                    />
                    <Input
                        placeholder='Укажите ссылку на файл'
                        onChange={event => changeFileLink(event)}
                        value={fileLink}
                        setValue={setFileLink}
                        // withTooltip={true}
                        // toolTipText={' Можете использовать ссылки из различных источников, таких как Вконтакте, YouTube и др.' +
                        //     ' А также использать url-адрес картинок.'}
                    />
                    <MySelectTagsComponent
                        tags={tags}
                        setTags={setTags}
                        selectedTags={chosenTags}
                        setSelectedTags={setChosenTags}
                    />

                    {file &&
                        <div className='chosen-file'>
                            <img src={checkMarkSvg} alt=''/>
                            <span className='selected-file'>{fileNamePreview(file.name)}</span>
                            <button
                                className='no-border-button'
                                onClick={() => {
                                    setFile(null);
                                    setTempUrl(null);
                                }}
                            >
                                <img src={exitSvg} alt='X'/>
                            </button>
                        </div>
                    }
                    <div className='file-selector'>
                        <label htmlFor='upload-input' className='upload-btn'>Загрузить файл</label>
                        <input accept='image/*,.png,.jpg,.gif,.jpeg, video/mp4' type='file' id='upload-input' onChange={event => chooseFileHandler(event)}/>
                    </div>

                    <div>
                        <Button text="Добавить мем" onClick={createMemeHandler} maxWidth={true}></Button>
                    </div>
                </div>
            </div>
            <ChooseFromSimilarMemesWindow
                showWindowHasSimilarMeme={showWindowHasSimilarMeme}
                setShowWindowHasSimilarMeme={setShowWindowHasSimilarMeme}
                similarMemeFile={similarMemeFile}
                tempUrl={tempUrl}
                fileLink={fileLink}
                createMemeHandler={createMemeHandler}
            />
        </div>
    );
}

export default CreateMemeWindow;