import './DisplayTypeMeme.css';
import {privateModeImage} from "../../helpers/memeHelper";
import {Constants} from "../../helpers/Constants";
import {getCurrentLocale, getMessage} from "../../helpers/messageHelper";
import {Messages} from "../../helpers/messages";

export const DisplayTypeMeme = ({meme}) => {

    const getText = () => {
        switch (meme.privateMode) {
            case Constants.PRIVATE_MODE.public:
                return getMessage(Messages.MEME_PUBLIC);
            case Constants.PRIVATE_MODE.for_friends:
                return getMessage(Messages.MEME_FOR_FRIENDS);
            case Constants.PRIVATE_MODE.private:
                return getMessage(Messages.MEME_PRIVATE);
            default:
                return '';
        }
    }


    return (
        <div className='display-type-meme-container'>
            {privateModeImage(meme)}
            <span>{getText()}</span>
        </div>
    )
}
