import avatarPlaceholder from "../../assets/images/placeholder.png";
import './Avatar.css';

export const Avatar = ({avatar=null, width=32, height=32, onClick=null}) => {

    const className = 'round ' + (onClick !== null ? 'pointer' : '');

    return (
        <div>
            <img
                src={avatar ?? avatarPlaceholder}
                alt=''
                width={width}
                height={height}
                className={className}
                onClick={onClick !== null ? onClick : () => {}}
            />
        </div>
    );
}