import './MemeCardSkeleton.css';

export const MemeCardSkeleton = () => {

    return (
        <div className='card skeleton'>
            <div className='card-creator-skeleton'>
                <div className="card-creator">
                    <div className='user-wrapper'>
                        <div>
                            <div className='skeleton-user-plug round'>
                            </div>
                        </div>
                        <div className='user-name'>
                            <div className='skeleton-user-name-plug'>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='tag-collection-skeleton'>
                <div className='tags'>
                    <div className='tag-skeleton'></div>
                    <div className='tag-skeleton'></div>
                    <div className='tag-skeleton'></div>
                    <div className='tag-skeleton'></div>
                </div>
            </div>
            <div className='card-meme-name'>
                <div className='meme-name-skeleton'></div>
            </div>
           <div className="meme-content-skeleton">
                <div className='card-body-skeleton'></div>
           </div>
            <div className='btn-keeper'>
                <div className='mark-buttons'>
                    <div className='btn btn-mark-skeleton'></div>
                    <div className='btn btn-mark-skeleton'></div>
                </div>
                <div className='comment-button-wrapper'>
                    <div className='btn btn-mark-skeleton'></div>
                </div>
            </div>
        </div>
    );
}