import {FriendCard} from "./FriendCard";
import './FriendList.css';
import React, {useEffect, useState} from "react";
import {Constants} from "../../helpers/Constants";
import {ReactComponent as FriendsSvg} from '../../assets/images/friends.svg'
import {ModeFilter} from "../Filters/ModeFilter";
import {SearchInput} from "../Common/SearchInput/SearchInput";
import {useParams} from "react-router-dom";
import useUser from "../../hooks/useUser";

export const FriendList = ({
    friends,
    setFriends
}) => {

    const {login} = useParams();

    const {user, setUser} = useUser();

    const [activeTab, setActiveTab] = useState(Constants.STATUS_FRIENDSHIP.APPROVE_FRIEND);
    const [filteredFriends, setFilteredFriends] = useState([]);

    const [searchLogin, setSearchLogin] = useState(null);

    const filter = () => {
        let fr = friends;

        if (searchLogin !== null && searchLogin !== '') {
            fr = friends.filter((friend) => friend.user.name.includes(searchLogin));
        }
        setFilteredFriends(fr.filter((friend) => friend.status === activeTab));
    }

    useEffect(() => {
        filter();
    }, [activeTab, friends, searchLogin]);

    const updateFriend = (changeFriend) => {
        console.log(changeFriend);
        const updatedFriends = friends.map((friend) => {
            if (changeFriend.id === friend.user.id) {
                return {user: changeFriend, status: changeFriend.status, statusCurrentUser: changeFriend.statusCurrentUser};
            }
            return friend;
        });
        setFriends(updatedFriends);
    }

    return (
        <div>
            <div className='friends-search'>
                <div>Поиск по друзьям</div>
                <SearchInput
                    onChange={(e) => {
                        setSearchLogin(e.target.value);
                    }}
                    handleKey={filter}
                    placeholder='Введите имя друга'
                />
            </div>
            <div className='friends-list-wrapper'>
                {login === user.name &&
                    <div className='friends-type-filter'>
                        <ModeFilter
                        options={[
                            {
                                value: Constants.STATUS_FRIENDSHIP.APPROVE_FRIEND,
                                text: 'Друзья',
                                count: friends.filter((f) => f.status === Constants.STATUS_FRIENDSHIP.APPROVE_FRIEND).length,
                                icon: FriendsSvg
                            },
                            {
                                value: Constants.STATUS_FRIENDSHIP.FRIEND,
                                text: 'Исходящие заявки',
                                count: friends.filter((f) => f.status === Constants.STATUS_FRIENDSHIP.FRIEND).length,
                                icon: FriendsSvg
                            },
                            {
                                value: Constants.STATUS_FRIENDSHIP.FRIEND_WITH_ME,
                                text: 'Входящие заявки',
                                count: friends.filter((f) => f.status === Constants.STATUS_FRIENDSHIP.FRIEND_WITH_ME).length,
                                icon: FriendsSvg
                            },
                        ]}
                        mode={activeTab}
                        setMode={setActiveTab}
                    />
                    </div>
                }
                <div className='friends-list'>
                    {filteredFriends.length === 0 ?
                        <div className='empty-friend-category'>Пусто</div>
                        :
                        filteredFriends.map((friend) => {
                            return (<FriendCard
                                key={friend.user.id}
                                friend={friend.user}
                                status={friend.status}
                                statusCurrentUser={friend.statusCurrentUser}
                                updateFriendCallback={updateFriend}
                            />);
                        })}
                </div>
            </div>
        </div>
    );
}