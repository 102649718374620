import './MemeCard.css';
import {TagCollection} from "../TagCollection/TagCollection";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {MemeContent} from "./MemeContent";
import {Constants} from "../../helpers/Constants";
import {useEffect, useRef, useState} from "react";
import {ReactComponent as CommentSvg} from '../../assets/images/comments.svg';
import {privateModeImage} from "../../helpers/memeHelper";
import {MarkButtons} from './MarkButtons';
import {CardCreator} from "./CardCreator";
import {TagPlug} from "../TagPlug/TagPlug";

export const MemeCard = ({meme: memeInit, callbackRemove}) => {

    const location = useLocation();
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const [meme, setMeme] = useState(memeInit);

    const memeContentContainerRef = useRef();

    useEffect(() => {
        setMeme(memeInit);
    }, [memeInit]);

    const namePlug =
        (location.pathname === Constants.ROUTES.collection || location.pathname === Constants.ROUTES.my_collection)
            ? 'Без названия' : 'Название мема';

    return (
        <div className='card'>
            <CardCreator meme={meme} callbackRemove={callbackRemove}/>
            {meme.tags.length === 0 ?
                <div className='tags'>
                    <TagPlug/>
                </div>
                :
                <TagCollection tags={meme.tags}/>
            }
            <div className='card-meme-name'>
                {(!meme.userMemeName /*&& meme.temp*/) ?
                    <div className='card-meme-name-plug'>{namePlug}</div>
                    :
                    meme.userMemeName
                }
            </div>
            <Link to={Constants.ROUTES.meme_detail + meme.owner.name + '/' + meme.id} ref={memeContentContainerRef} className='card-body' state={{meme: meme}}>
                <MemeContent
                    memeFile={meme.memeFile}
                    width={298}
                    height={320}
                    containerRef={memeContentContainerRef}
                />
                {meme.privateMode !== 0 && <div className='meme-private-mode'>{privateModeImage(meme)}</div>}
            </Link>
            <div className='btn-keeper'>
                {!meme.temp &&
                    <>
                        <MarkButtons meme={meme} setMeme={setMeme}/>
                        <div className='comment-button-wrapper'>
                            <button className='comment-button' onClick={() => navigate(Constants.ROUTES.meme_detail +  meme.owner.name + '/' + meme.id)}>
                                <CommentSvg className='comment-svg'/>
                            </button>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}