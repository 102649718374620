import React from "react";
import './Input.css';
import exitSvg from '../../../assets/images/exit.svg';
import infoSvg from '../../../assets/images/info.svg';

export const Input = ({
    placeholder= '',
    type='text',
    onChange,
    value,
    setValue,
    onKeyUp = () => {},
    onKeyDown = () => {},
    withTooltip = false,
    toolTipText = '',
    isError = false,
    errorMsg = null,
    autocomplete = 'on'
}) => {

    return (
        <div className='input-wrapper'>
            <div className={isError ? 'common-input error-input' : 'common-input'}>
                <input
                    type={type}
                    autoComplete={autocomplete}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    onKeyUp={onKeyUp}
                    onKeyDown={onKeyDown}
                    style={{minWidth: `calc(100% - ${withTooltip && value ? 64 : 32}px)`}}
                />
                {withTooltip &&
                    <div className='tooltip'>
                        <div>
                            <div className="tooltiptext-container">
                                <div className='tooltip-square'></div>
                                <span className="tooltiptext">
                               {toolTipText}
                            </span>
                            </div>
                            <img src={infoSvg} alt=''/>
                        </div>
                    </div>
                }
                {value &&
                    <div>
                        <button onClick={() => setValue('')}>
                            <img src={exitSvg} alt=''/>
                        </button>
                    </div>
                }
            </div>
            {errorMsg && <span className='input-error-message'>{errorMsg}</span>}
        </div>
    );
}