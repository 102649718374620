import React from "react"
import './YesNoDialog.css'
import {Button} from "../Button/Button";

export const YesNoDialog = ({message, okAction, cancelAction}) => {
    return (
       <div>
           <div className='message'>
               <span>{message}</span>
           </div>
           <div className='buttons'>
               <Button text="Да" onClick={okAction}></Button>
               <Button text="Отмена" onClick={cancelAction}></Button>
           </div>
       </div>
    );
}