import './BurgerMenu.css';
import React, {useEffect, useRef} from "react";
import {HeaderButton} from "../HeaderButton";
import {Constants} from "../../../helpers/Constants";
import useUser from "../../../hooks/useUser";
import {useNavigate} from "react-router-dom";

export const BurgerMenu = ({active, setActive}) => {

    const containerRef = useRef();
    const navigate = useNavigate();
    const {user, setUser} = useUser();

    useEffect(() => {
        if (!active) {
            return;
        }

        const handleClick = (e) => {

            if (!containerRef.current) {
                return;
            }

            if (!containerRef.current.contains(e.target)) {
                setActive(false);
            }
        }

        setTimeout(() => document.addEventListener('click', handleClick),0.1);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [active]);

    return (
        <div className='burger-background'>
            <div className='burger-menu-wrapper'>
                <div className='burger-menu' ref={containerRef}>
                    <HeaderButton text="Глобальная коллекция" path={Constants.ROUTES.home} withBorder={false}/>
                    <HeaderButton text="Моя коллекция" disabled={!user}
                                  path={Constants.ROUTES.my_collection} withBorder={false}/>
                    <HeaderButton text="Профиль" disabled={!user}
                                  path={Constants.ROUTES.profile + user?.name} withBorder={false}/>
                    <HeaderButton text="Мои Теги" disabled={!user} path={Constants.ROUTES.tag_create}
                                  withBorder={false}/>
                </div>
            </div>
        </div>
    );
}