import {useState} from "react";
import './PrivateModeOption.css';
import {Constants} from "../../helpers/Constants";

export const PrivateModeOption = ({text, value, currentValue, setValue}) => {

    const getActiveClass = () => {
        if (value === Constants.PRIVATE_MODE.private) {
            return ' active-option-private';
        }
        if (value === Constants.PRIVATE_MODE.for_friends) {
            return ' active-option-friends';
        }
        if (value === Constants.PRIVATE_MODE.public) {
            return ' active-option-public';
        }
    }

    return (
      <>
          <button
              className={value !== currentValue ? 'private-selector-meme-option' : 'private-selector-meme-option' + getActiveClass()}
              onClick={() => {
                  setValue(value);
              }}
          >
              {text}
          </button>
      </>
    );
}