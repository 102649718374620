import React, {useState} from "react";
import {Button} from "../Common/Button/Button";
import {PrivateModeSelector} from "../PrivateModeSelector/PrivateModeSelector";
import './CreateTagModalContent.css';

export const CreateTagModalContent = ({tag, callback, setModalIsActive}) => {

    const [privateModeTag, setPrivateModeTag] = useState(0);

    const createButtonHandler = () => {
        callback(tag, privateModeTag);
        setModalIsActive(false);
    }

    const cancelButtonHandler = () => {
        setModalIsActive(false);
    }

    return (
        <div className='create-tag-modal-content'>
            {tag && <span>Новый тег: "{tag.value}"</span>}
            <div>
                <PrivateModeSelector
                    privateMode={privateModeTag}
                    setPrivateMode={setPrivateModeTag}
                    text="Выберите модификатор доступа для тега"
                />
            </div>

            <div className='create-tag-modal-buttons'>
                <Button text="Создать" onClick={createButtonHandler}/>
                <Button text="Отмена" onClick={cancelButtonHandler}/>
            </div>
        </div>
    );
}