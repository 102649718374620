import React, {useEffect, useRef, useState} from "react";
import './MyCreatableSelectTags.css';
import {Tag} from "../Tag/Tag";
import {SelectedTags} from "./SelectedTags";
import arrowDownSvg from '../../assets/images/arrow-down.svg';
import arrowUpSvg from '../../assets/images/arrow-up-combo.svg';
import {Modal} from "../Common/Modal/Modal";
import {CreateTagModalContent} from "../CreateTagModalContent/CreateTagModalContent";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import {ReactComponent as TrashSvg} from '../../assets/images/trash.svg';
import {useDispatch} from "react-redux";
import {setUserCollection} from "../../store/memesCollectionSlice";
import {useLocation} from "react-router-dom";
import {Constants} from "../../helpers/Constants";

export const MySelectTagsComponent = ({
  tags,
  setTags,
  selectedTags,
  setSelectedTags,
  filter = null,
  creatable = true
}) => {
    const [value, setValue] = useState('');
    const [showTags, setShowTags] = useState(false);
    const [filteredTags, setFilteredTags] = useState([]);

    const dispatch = useDispatch();
    const location = useLocation();

    const [alreadyEdit, setAlreadyEdit] = useState(false);

    const spaceForTagsRef = useRef();
    const arrowButtonRef = useRef();

    const [choosePrivateModeTagIsActive, setChoosePrivateModeTagIsActive] = useState(false);
    const [createTag, setCreateTag] = useState(null);

    const createNewTag = async (tag, tagPrivateMode) => {
        const api = new Api(true);
        try {
            const response = await api.createTag({
                name: tag.value,
                privateMode: tagPrivateMode
            });
            const data = response.data;
            setTags([...tags, data]);
            setSelectedTags(selectedTags => [...selectedTags, data]);
            setValue('')
            toast('Тег создан!');
        } catch (e) {
            toast(e.message);
        }
    }

    useEffect(() => {
        const selectedTagIds = selectedTags.map(t => t.id);
        console.log(tags);
        const tagsWithoutSelected = tags.filter((tag) => {
            return !selectedTagIds.includes(tag.id);
        });

        if (alreadyEdit) {
            setShowTags(true);
        }

        // console.log(tagsWithoutSelected);

        setFilteredTags(tagsWithoutSelected.filter((t) => {
            return t.name.toLowerCase().includes(value.toLowerCase());
        }));
    }, [value, tags, selectedTags]);

    useEffect(() => {
        const handleClickOutside = (event) => {

            if (choosePrivateModeTagIsActive) return;

            if (spaceForTagsRef.current && !spaceForTagsRef.current.contains(event.target)) {
                if (arrowButtonRef.current && !arrowButtonRef.current.contains(event.target)) {
                    setShowTags(false);
                }
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [spaceForTagsRef, choosePrivateModeTagIsActive]);

    useEffect(() => {
        if (filter !== null && !showTags) {
            filter();
        }
    }, [selectedTags, showTags]);

    const getTags = () => {

        const tagsFiltered = filteredTags.map((tag) => (
            <Tag id={tag.id} tag={tag} className='for-creatable' callback={selectTags}/>
        ));

        if (tagsFiltered.length === 0) {
            return <div>
                <div className='tag-not-found'>
                    {value ? 'Введенный тег не найден' : 'Нет тегов'}
                </div>
                {!tagAlreadyExist(value) && creatable &&
                    <div className='create-tag' onClick={() => {
                        setCreateTag({value: value})
                        setChoosePrivateModeTagIsActive(true)
                    }}>
                        Создать тег "{value}"
                    </div>
                }
            </div>
        }

        return tagsFiltered;
    }

    const tagAlreadyExist = (value) => {
        if (!value) return false;
        for (let i = 0; i < selectedTags.length; i++) {
            if (selectedTags[i].name === value) {
                return true;
            }
        }

        return false;
    }

    const onArrowClick = () => {
        setShowTags(!showTags);
    }

    const selectTags = (selectTag) => {
        setSelectedTags([...selectedTags, selectTag])
    }

    const onChange = (event) => {
        const value = event.target.value;
        setAlreadyEdit(true);
        setValue(value);
    }

    const getClassName = () => {
        let className = 'space-for-tags';
        className += filteredTags.length === 0 ? ' small-height' : '';

        className += selectedTags.length !== 0 ? ' margin-more' : '';

        return className;
    }

    return (
        <>
            <div className='input-wrapper'>
                <div className='common-input-selected-tags'>
                    <div className='selected-tags-input'>
                        <input
                            type='text'
                            onChange={onChange}
                            // onInput={() => console.log('onInput')}
                            onClick={() => setShowTags(true)}
                            placeholder='Введите название тега'
                            value={value}
                            style={{minWidth: `calc(100% - 32px`}}
                        />
                        <div className='pointer' onClick={onArrowClick} ref={arrowButtonRef}>
                            <img src={!showTags ? arrowDownSvg : arrowUpSvg} alt={'v'} width={'20px'} height={'20px'}/>
                        </div>
                    </div>
                    {selectedTags.length > 0 && <>
                        <SelectedTags selectedTags={selectedTags} setSelectedTags={setSelectedTags}/>
                        <div className='remove-all-tags-button pointer' onClick={() => {
                            setSelectedTags([]);
                            if (location.pathname === Constants.ROUTES.my_collection) {
                                dispatch(setUserCollection([]));
                            }
                        }}>
                            <TrashSvg className={'trash-svg'}/> Очистить теги
                        </div>
                    </>
                    }
                </div>
                {showTags && <div className={getClassName()} ref={spaceForTagsRef}>
                    {getTags()}
                </div>}
            </div>

            <Modal active={choosePrivateModeTagIsActive} setActive={setChoosePrivateModeTagIsActive}>
                <CreateTagModalContent
                    tag={createTag}
                    callback={createNewTag}
                    setModalIsActive={setChoosePrivateModeTagIsActive}
                />
            </Modal>
        </>
    );
}
