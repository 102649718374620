import {rateMeme, rateMemeFile} from "../../helpers/memeHelper";
import useUser from "../../hooks/useUser";
import {ReactComponent as LikeSvg} from '../../assets/images/like.svg';
import {ReactComponent as DislikeSvg} from '../../assets/images/dislike.svg';
import './MarkButtons.css'
import {useDispatch} from "react-redux";
import {
    setGlobalCollection,
    setGlobalTags,
    updateMemeFromGlobalCollection,
    updateMemeFromUserCollection
} from "../../store/memesCollectionSlice";
import {setGlobalSearchDefaultSettings} from "../../store/searchGlobalCollectionSlice";

export const MarkButtons = ({meme, setMeme}) => {

    const {user, setUser} = useUser();

    const isMemeFile = meme.memeFile === undefined; // если нет этого поля, то это мем из публичной коллекции

    const dispatch = useDispatch();
    const updateInUserCollection = (meme) => {dispatch(updateMemeFromUserCollection(meme)) };
    const updateInGlobalCollection = (meme) => {dispatch(updateMemeFromGlobalCollection(meme))};

    const callback = (meme) => {
        if (isMemeFile) {
            console.log('like');
            dispatch(updateMemeFromGlobalCollection(meme));
            return;
        }
        dispatch(updateMemeFromUserCollection(meme));
        dispatch(setGlobalCollection([]));
        dispatch(setGlobalSearchDefaultSettings());
        dispatch(setGlobalTags([]));
    }

    const rateMemeFunc = isMemeFile ? rateMemeFile : rateMeme;

    return (
        <div className='mark-buttons'>
            <button className={'mark-button ' + (meme.mark === 1 ? 'btn-like active-like' : 'btn-like')} onClick={() => rateMemeFunc(1, user, meme, setMeme, callback)}>
                <div className='btn'>
                    <LikeSvg className={meme.mark === 1 ? 'fill-mark-svg' : ''}/>
                    {+meme.countLikes !== 0 &&  <span>{+meme.countLikes}</span>}
                </div>
            </button>
            <button className={'mark-button ' + (meme.mark === 0 ? 'btn-dislike active-dislike' : 'btn-dislike')} onClick={() => rateMemeFunc(0, user, meme, setMeme, callback)}>
                <div className='btn'>
                    <DislikeSvg className={meme.mark === 0 ? 'fill-mark-svg' : ''}/>
                    {+meme.countDislikes !== 0 &&  <span>{+meme.countDislikes}</span>}
                </div>
            </button>
        </div>
    );
}