import React, {useEffect} from "react";
import './GlobalCollectionPage.css';
import Api from "../../actions/apiCallHelper";
import {MainBlock} from "../../components/MainBlock/MainBlock";
import {useDispatch, useSelector} from "react-redux";
import useUser from "../../hooks/useUser";
import {setGlobalCollection, setUserCollection} from "../../store/memesCollectionSlice";
import {
    setGlobalSearchHasMore, setGlobalSearchMode, setGlobalSearchOrderBy,
    setGlobalSearchPage,
    setGlobalSearchTagIds
} from "../../store/searchGlobalCollectionSlice";

export const GlobalCollectionPage = () => {

    const dispatch = useDispatch();
    const {user, setUser} = useUser();

    const hasMore = useSelector((state) => state.searchGlobalCollection.hasMore);
    const currPage = useSelector((state) => state.searchGlobalCollection.page);
    const orderBy = useSelector((state) => state.searchGlobalCollection.orderBy);
    const tagIds = useSelector((state) => state.searchGlobalCollection.tagIds);
    const mode = useSelector((state) => state.searchGlobalCollection.mode);

    const memes = useSelector((state) => state.memesCollection.globalCollection);
    const setMemes = (value) => {
        dispatch(setGlobalCollection(value));
    }

    const searchPublicMemes = async ({currPage, memeName, tagIds, mode, orderBy}) => {
        const api = new Api(user);

        console.log(currPage);

        return await api.getPublicMemes({page: currPage, memeName, tagIds, mode, orderBy});
    }

    return (
        <div>
            <MainBlock
                searchMemes={searchPublicMemes}
                memes={memes}
                setMemes={setMemes}
                currPage={currPage}
                orderBy={orderBy}
                tagIds={tagIds}
                mode={mode}
                hasMore={hasMore}
                setPage={setGlobalSearchPage}
                setHasMore={setGlobalSearchHasMore}
                setTagIds={setGlobalSearchTagIds}
                setMode={setGlobalSearchMode}
                setOrderByState={setGlobalSearchOrderBy}
            />
        </div>
    );
}