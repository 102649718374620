import {Messages_ru} from "../assets/resources/messages_ru";
import {Messages_en} from "../assets/resources/Messages_en";

export const getCurrentLocale = () => {
    return Messages_ru;
}

export const getMessage = (key) => {
    const locale = getCurrentLocale();

    if (!(key in locale)) {
        console.error('Не реализовано сообщение на языке');
    }

    return getCurrentLocale()[key];
}