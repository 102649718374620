import {createSlice} from '@reduxjs/toolkit'
import {MAX_MEMES_FOR_PAGE} from "../actions/apiCallHelper";

const initialState = {
    page: 1,
    tagIds: [],
    mode: 'strict',
    memeName: null,
    hasMore: true,
    orderBy: 'new',
    limit: MAX_MEMES_FOR_PAGE
}

export const searchUserCollectionSlice = createSlice({
    name: 'searchUserCollection',
    initialState,
    reducers: {
        setUserSearchMemeName: (state, action) => {
            state.memeName = action.payload.memeName;
        },
        setUserSearchTagIds: (state, action) => {
            state.tagIds = action.payload.tagIds;
        },
        setUserSearchPage: (state, action) => {
            state.page = action.payload.page;
        },
        setUserSearchMode: (state, action) => {
            state.mode = action.payload.mode;
        },
        setUserSearchOrderBy: (state, action) => {
            state.orderBy = action.payload.orderBy
        },
        setUserSearchDefaultSettings: (state) => {
            state.page = 1;
            state.tagIds = [];
            state.mode = 'strict';
            state.memeName = null;
            state.limit = MAX_MEMES_FOR_PAGE;
            state.hasMore = true;
        },
        setUserSearchHasMore: (state, action) => {
            state.hasMore = action.payload.hasMore;
        }
    },
})

export const {
    setUserSearchMemeName,
    setUserSearchTagIds,
    setUserSearchPage,
    setUserSearchMode,
    setUserSearchOrderBy,
    setUserSearchDefaultSettings,
    setUserSearchHasMore
} = searchUserCollectionSlice.actions

export default searchUserCollectionSlice.reducer