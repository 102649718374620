import React from "react";
import './Loader.css';
import {Oval} from "react-loader-spinner";

export const Loader = ({size=300, text=null, useStyle=true}) => {

    return (
        <div className={useStyle ? 'loader' : ''}>
            {text &&
            <b>{text}</b>}
            <Oval
                height={size}
                width={size}
                radius="9"
                color="grey"
                secondaryColor='#946BFF'
                ariaLabel="loading"
            />
        </div>
    );
}