import React from "react";
import './ModeFilterOption.css';

export const ModeFilterOption = ({value, count=null, SVGIcon, text, currentValue, setValue, extraAction}) => {

    const getContent = () => {

        return (
            <div className='mode-filter-option'>
                <SVGIcon className={value !== currentValue ? 'option-disable-fill' : ''}/>
                <span>{text} {count !== null && `(${count})`}</span>
            </div>
        )
    }

    return (
        <>
            <button
                className= {value !== currentValue ? 'no-border-button mode-filter-option-disable' : 'no-border-button mode-filter-option-enable'}
                onClick={() => {
                    setValue(value);
                    if (extraAction !== null) {
                        console.log('extra_action');
                        extraAction(value);
                    }
                }}
            >
                {getContent()}
            </button>
        </>
    );
}