import './NotificationList.css';
import {Notification} from "./Notification";
import {useEffect, useRef} from "react";

export const NotificationList = ({notifications, showNotification, setShowNotification}) => {

    const notificationListRef = useRef();

    useEffect(() => {
        if (!showNotification) {
            return;
        }

        const handleClick = (e) => {

            if (!notificationListRef.current) {
                return;
            }

            if (!notificationListRef.current.contains(e.target)) {
                setShowNotification(false);
            }
        }

        setTimeout(() => document.addEventListener('click', handleClick),0.1);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [showNotification]);

    return (
        <div className='notification-wrapper' ref={notificationListRef}>
            <div className='notification-header'>
                Ваши уведомления
            </div>
            <div className='notifications'>
                {notifications.length ? notifications.map(notification => (
                    <Notification notification={notification} key={notification.id}/>
                )): ''}
            </div>
        </div>
    );
}