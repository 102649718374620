import React from "react";
import {ModeFilterOption} from "./ModeFilterOption";
import './ModeFilter.css';

export const ModeFilter = ({options, mode, setMode, extraAction=null}) => {

    const getOptions = () => {
        return options.map((option) => {
            return (
                <ModeFilterOption
                    value={option.value}
                    count={option.count}
                    text={option.text}
                    SVGIcon={option.icon}
                    currentValue={mode}
                    setValue={setMode}
                    extraAction={extraAction}
                    key={`key-option-${option.value}`}
                />
            );
        })
    };

    return (
        <div className='mode-filter'>
            {getOptions()}
        </div>
    );
}