import React, {useEffect, useRef} from "react";
import './Options.css';

export const Options = ({options, showContextMenu, setShowContextMenu}) => {

    const refOptions = useRef();

    if (!Array.isArray(options)) {
        options = [options];
    }

    useEffect(() => {
        if (!showContextMenu) {
            return;
        }

        const handleClick = (e) => {

            if (!refOptions.current) {
                return;
            }

            if (!refOptions.current.contains(e.target)) {
                setShowContextMenu(false);
            }
        }

        setTimeout(() => document.addEventListener('click', handleClick),0.1);
        return () => {
            document.removeEventListener('click', handleClick);
        }
    }, [showContextMenu]);

    return (
        showContextMenu &&
        <div ref={refOptions} className='options-wrapper'>
            <div className='options'>
                {options.length > 0 ? options.map(option => {
                    console.log(option); return option;
                    }) : ''}
            </div>
        </div>
    );
}