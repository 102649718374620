import React, {useEffect, useState} from "react";
import {MainBlock} from "../../components/MainBlock/MainBlock";
import Api from "../../actions/apiCallHelper";
import useUser from "../../hooks/useUser";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {setUserCollection} from "../../store/memesCollectionSlice";
import {
    setUserSearchHasMore,
    setUserSearchMemeName,
    setUserSearchMode,
    setUserSearchOrderBy,
    setUserSearchPage,
    setUserSearchTagIds
} from "../../store/searchUserCollectionSlice";

export const UserCollectionPage = () => {
    const {user, setUser} = useUser();

    const dispatch = useDispatch();
    const memes = useSelector((state) => state.memesCollection.userCollection);
    const hasMore = useSelector((state) => state.searchUserCollection.hasMore);
    const currPage = useSelector((state) => state.searchUserCollection.page);
    const orderBy = useSelector((state) => state.searchUserCollection.orderBy);
    const memeName = useSelector((state) => state.searchUserCollection.memeName);
    const tagIds = useSelector((state) => state.searchUserCollection.tagIds);
    const mode = useSelector((state) => state.searchUserCollection.mode);

    const setMemes = (value) => {
        dispatch(setUserCollection(value));
    }

    let {login} = useParams();
    if (!login && user) {
        login = user.name;
    }

    const searchUserMemes = async ({currPage, memeName, tagIds, mode, orderBy}) => {
        const api = new Api(user);
        return await api.getMemesUser({loginUser: login, page: currPage, memeName, tagIds, mode, orderBy});
    }

    return (
        <div>
            <MainBlock
                searchMemes={searchUserMemes}
                memes={memes}
                setMemes={setMemes}
                currPage={currPage}
                orderBy={orderBy}
                memeName={memeName}
                tagIds={tagIds}
                mode={mode}
                hasMore={hasMore}
                setPage={setUserSearchPage}
                setHasMore={setUserSearchHasMore}
                setTagIds={setUserSearchTagIds}
                setMemeName={setUserSearchMemeName}
                setMode={setUserSearchMode}
                setOrderByState={setUserSearchOrderBy}
            />
        </div>
    );
}