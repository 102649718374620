import {Constants} from "./Constants";
import {toast} from "react-hot-toast";
import {getGets} from "./common";
import Api from "../actions/apiCallHelper";
import friendsSvg from '../assets/images/friends.svg';
import privateSvg from '../assets/images/private.svg';
import publicSvg from '../assets/images/public.svg';

export const copyMemeImg = async (memeFile) => {

    const writeToCanvas = (src) => {
        return new Promise((res) => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = src;

            img.onload = () => {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
                ctx.drawImage(img, 0, 0);
                canvas.toBlob((blob) => {
                    res(blob);
                }, 'image/png')
            }

        }).catch(e => {
            console.log(e);
        })
    }

    console.log(memeFile);

    const imgEl = document.getElementById('meme-' + memeFile.id);

    console.log('imgEl', imgEl);

    switch (memeFile.type) {
        case Constants.VIDEO_TYPE:
            const src = imgEl.getElementsByTagName("source")[0].src;
            toast('Ссылка скопирована');
            return await navigator.clipboard.writeText(src);

        case Constants.VIDEO_YOUTUBE_TYPE:
            const videoId = (imgEl.src.split('/').reverse())[0];
            const youtubeLink = 'https://www.youtube.com/watch?v=' + videoId;
            toast('Ссылка скопирована');
            return await navigator.clipboard.writeText(youtubeLink);

        case Constants.VIDEO_VK_TYPE:
            const params = getGets(imgEl.src)
            const vkLink = 'https://vk.com/video' + params[0] + '_' + params[1];
            toast('Ссылка скопирована');
            return await navigator.clipboard.writeText(vkLink);
    }

    try {
        const blob = await writeToCanvas(imgEl.src);
        await navigator.clipboard.write([
            new ClipboardItem({
                [blob.type]: blob,
            }),
        ]);
        toast('Изображение скопировано');
    } catch (e) {
        await navigator.clipboard.writeText(imgEl.src);
        toast('Ссылка скопирована');
        console.log(e);
    }
}

export const privateModeImage = (meme) => {

    switch (meme.privateMode) {
        case 0:
            return (<img src={publicSvg} alt=''/>);
        case 1:
            return (<img src={friendsSvg} alt=''/>);
        case 2:
            return (<img src={privateSvg} alt=''/>);
        default:
            return '';
    }
};

export const rateMeme = async (mark, user, meme, setMeme, callback) => {
    if (!user) {
        toast('Необходимо авторизоватья!');
        return;
    }
    const api = new Api(true);
    const initMeme = Object.assign({}, meme);
    let countLikes = meme.countLikes;
    let countDislikes = meme.countDislikes;
    let localMark = mark;

    if (meme.mark !== localMark) {
        if (localMark === 1) {
            countLikes++;
            if (meme.mark !== null) {
                countDislikes--;
            }
        } else {
            countDislikes++;
            if (meme.mark !== null) {
                countLikes--;
            }
        }
    } else {
        if (localMark === 1) {
            countLikes--;
        } else {
            countDislikes--;
        }
        localMark = null;
    }
    setMeme({...meme,
        mark: localMark,
        countLikes: countLikes,
        countDislikes: countDislikes
    });

    try {
        const response = await api.rateMeme({
            memeId: meme.id,
            mark: mark
        });

        callback({...meme,
            mark: localMark,
            countLikes: countLikes,
            countDislikes: countDislikes
        });


    } catch (e) {
        console.log(e);
        setMeme(initMeme);
    }
}

export const rateMemeFile = async (mark, user, meme, setMeme, callback) => {
    if (!user) {
        toast('Необходимо авторизоватья!');
        return;
    }
    const api = new Api(true);
    try {
        const response = await api.rateMemeFile({
            memeFileId: meme.id,
            mark: mark
        });

        setMeme(response.data);

        callback({...meme,
            mark: response.data.mark,
            countLikes: response.data.countLikes,
            countDislikes: response.data.countDislikes
        });

    } catch (e) {
        toast(e.message);
    }
}

export const shareMeme = async (memeId) => {
    const host = window.location.protocol + "//" + window.location.host;
    const url = host + Constants.ROUTES.meme_detail + memeId;
    await navigator.clipboard.writeText(url);
    toast('Ссылка скопирована');
}

export const addComment = async ({commentText, memeFileId, callback, memeId=null}) => {
    const api = new Api(true);
    try {
        const response = await api.addComment({
            memeFileId: memeFileId,
            memeId: memeId,
            text: commentText
        });
        callback(response.data);
        toast('Комментарий добавлен!');
    } catch (e) {
        console.log(e);
        toast(e?.message ?? 'Ошибка!');
    }
}

export const deleteComment = async (commentId, callback) => {
    const api = new Api(true);
    try {
        await api.deleteComment(commentId);
        callback();
    } catch (e) {
        console.log(e);
    }
}