import './EditProfilePage.css';
import {Header} from "../../components/Header/Header";
import {UserBlock} from "../../components/User/UserBlock";
import {Input} from "../../components/Common/Input/Input";
import {Button} from "../../components/Common/Button/Button";
import {Avatar} from "../../components/Avatar/Avatar";
import React, {useState} from "react";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import useUser from "../../hooks/useUser";

export const EditProfilePage = () => {

    const {user, setUser} = useUser();

    const [login, setLogin] = useState(user.name);
    const [description, setDescription] = useState(user.description);
    const [avatarFile, setAvatarFile] = useState(null);
    const [tempAvatarUrl, setTempAvatarUrl] = useState(null);

    const chooseAvatarFileHandler = (event) => {
        const file = event.target.files[0];
        setAvatarFile(file);

        const url = URL.createObjectURL(file);
        setTempAvatarUrl(url);
    }

    const changeLogin = (event) => {
        setLogin(event.target.value);
    }

    const changeDescription = (event) => {
        setDescription(event.target.value);
    }

    const saveChanges = async () => {
        const api = new Api(true);
        try {
            const response = await api.updateUserProfile({
                login,
                description,
                avatarFile
            });
            setUser(response.data);
            toast('Информация профиля обновлена!');
        } catch (e) {
            console.log(e);
            toast(e.message);
        }
    };

    return (
        <div>
            <Header/>
            <div className='app-global-container'>
                <div className='main-block-content'>
                    <UserBlock/>
                    <div className='edit-user-page-inputs'>
                        <div className='edit-user-page-main-info'>
                            <span>Основная информация</span>
                            <div className='edit-user-page-input'>
                                <span>Логин</span>
                                <Input
                                    value={login}
                                    onChange={event => changeLogin(event)}
                                    setValue={setLogin}
                                    placeholder='Логин'
                                />
                            </div>
                            <div className='edit-user-page-input'>
                                <span>Описание</span>
                                <Input
                                    value={description}
                                    onChange={event => changeDescription(event)}
                                    setValue={setDescription}
                                    placeholder='Описание'
                                />
                            </div>
                            <div className='edit-profile-save-changes-button'>
                                <Button text='Сохранить изменения' onClick={saveChanges}/>
                            </div>
                        </div>
                        <div className='edit-page-avatar-info'>
                            <span>Аватар</span>
                            <div className='edit-user-page-avatar-container'>
                                <Avatar avatar={tempAvatarUrl ?? user?.avatar} width={48} height={48}/>
                                {/*<input type='file' onChange={event => chooseAvatarFileHandler(event)}/>*/}
                                <div className='file-selector'>
                                    <label htmlFor='upload-input' className='upload-avatar-button'>Загрузить фото</label>
                                    <input accept='image/*,.png,.jpg,.gif,.jpeg' type='file' id='upload-input' onChange={event => chooseAvatarFileHandler(event)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
