import './Tag.css';
import {Constants} from "../../helpers/Constants";
import {toast} from "react-hot-toast";
import {ContextMenuButton} from "../Common/ContextMenu/ContextMenuButton";
import {ContextMenuOption} from "../Common/ContextMenu/ContextMenuOption";
import './TagPage.css';
import deleteTagSvg from "../../assets/images/delete.svg";
import editTagSvg from '../../assets/images/edit.svg';

export const TagPage = ({tag, deleteHandler}) => {

    const getStyleForTag = () => {
        switch (tag.private) {
            case Constants.PRIVATE_MODE.for_friends:
                return 'tag-page-for-friends';
            case Constants.PRIVATE_MODE.private:
                return 'tag-page-private';
            default:
                return 'tag-page-default';
        }
    }

    return (
        <div className={'tag-page ' + getStyleForTag()}>
            <div className='tag-page-value'>
                {tag.name}
                <ContextMenuButton>
                    <ContextMenuOption
                        imgSrc={deleteTagSvg}
                        handler={() => deleteHandler(tag)}
                        text="Удалить"
                    />
                    {/*<ContextMenuOption*/}
                    {/*    imgSrc={deleteTagSvg}*/}
                    {/*    handler={() => deleteHandler(tag)}*/}
                    {/*    text="Удалить"*/}
                    {/*/>*/}
                </ContextMenuButton>
            </div>
        </div>
    );
}