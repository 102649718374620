import {useLocation, useNavigate} from "react-router-dom";
import useUser from "../../hooks/useUser";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Constants} from "../../helpers/Constants";
import logo from "../../assets/images/logo_dog.svg";
import {HeaderButton} from "./HeaderButton";
import {Button} from "../Common/Button/Button";
import search from "../../assets/images/search-bold.svg";
import burgerMenuIcon from "../../assets/images/burger-menu.svg";
import bell from "../../assets/images/bell.svg";
import {Avatar} from "../Avatar/Avatar";
import {NotificationList} from "../Notification/NotificationList";
import {UserMenu} from "./UserMenu/UserMenu";
import {SearchUserWindow} from "../SearchUsersWindow/SearchUserWindow";
import {BurgerMenu} from "./Burger/BurgerMenu";

export const HeaderBurger = () => {

    const location = useLocation();
    const {user, setUser} = useUser();
    const navigate = useNavigate();
    const notifications = useSelector((state) => state.notification.notifications);
    const [showNotification, setShowNotification] = useState(false);
    const [showUserMenu, setShowUserMenu] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [countNotReadableNotification, setCountNotReadableNotification] = useState(0);
    const [active, setActive] = useState(false);


    const getNotification = () => {
        setShowNotification(!showNotification);
    }
    //
    useEffect(() => {
        const count = notifications.filter((notification) => {
            return !notification.read;
        }).length;
        setCountNotReadableNotification(count);
    }, [notifications]);

    // useEffect(() => {
    //     if (showMenu) {
    //         alert('show menu');
    //     }
    // }, [showMenu])

    return (
        <>
            <div className="header">
                <div className='header-container'>
                    <nav className='menu-wrapper-mobile'>
                        <div className='menu-logo-mobile'>
                            <img className={'pointer'} src={logo} alt={''} onClick={() => navigate(Constants.ROUTES.home)}/>
                            <img className={'pointer'} src={burgerMenuIcon} alt={''} width={'50px'} height={'50px'} onClick={() => setActive(!active)}/>
                        </div>
                        {user ?
                            <div className='menu-profile'>
                                <Button
                                    className='add-meme-button'
                                    onClick={() => {
                                        navigate(Constants.ROUTES.meme_create);
                                    }}
                                    text="+"
                                    disabled={location.pathname === Constants.ROUTES.meme_create}
                                    fontSize={22}
                                />
                                <button onClick={() => setShowSearch(!showSearch)} className='no-border-button'>
                                    <div className='notification-button-content'>
                                        <img src={search} alt=''/>
                                    </div>
                                </button>
                                <button onClick={getNotification} className='no-border-button'>
                                    <div className='notification-button-content'>
                                        <img src={bell} alt={'уведомления'}/>
                                    </div>
                                </button>
                                {countNotReadableNotification > 0 &&
                                    <span className='notification-button-count'>
                                        {countNotReadableNotification}
                                    </span>
                                }
                                {/*<Avatar avatar={user?.avatar} width={48} height={48}*/}
                                {/*        onClick={() => setShowUserMenu(!showUserMenu)}/>*/}
                            </div> :
                            <div className='menu-profile-unathorized'>
                                <button className='default-white'
                                        onClick={() => navigate(Constants.ROUTES.signin)}>Вход
                                </button>
                                <button className='default'
                                        onClick={() => navigate(Constants.ROUTES.signup)}>Регистрация
                                </button>
                            </div>
                        }
                    </nav>
                    {active && <BurgerMenu active={active} setActive={setActive}/>
                    }
                    {showNotification &&
                        <NotificationList
                            notifications={notifications}
                            showNotification={showNotification}
                            setShowNotification={setShowNotification}
                        />
                    }
                    {showUserMenu &&
                        <UserMenu user={user} showContextMenu={showUserMenu}
                                  setShowContextMenu={setShowUserMenu}/>
                    }
                    {showSearch &&
                        <SearchUserWindow active={showSearch} setActive={setShowSearch}/>
                    }
                </div>
            </div>
            <div className='margin-top'>

            </div>
        </>
    );
}