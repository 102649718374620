import {NotFoundPage} from "../pages/NotFoundPage/NotFoundPage";

/**
 * Native scrollTo with callback
 * @param callback - callback function
 */
export function scrollToTop(callback = () => {}) {
    const onScroll = function () {
        if (window.scrollY === 0) {
            window.removeEventListener('scroll', onScroll)
            callback()
        }
    }

    window.addEventListener('scroll', onScroll)
    onScroll()
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

export const getGets = (url) => {
    const b = [];
    url = url.substring(1).split('&');
    for (let i = 0; i < url.length; i++) {
        const c = url[i].split("=");
        // b[c[0]] = c[1];
        b.push(c[1])
    }
    return b;
}

export const getNotFoundPageContent = () => {
    return (
        <>
            <NotFoundPage/>
        </>
    );
}