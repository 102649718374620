import React, {useEffect, useState} from "react";
import {Header} from "../../components/Header/Header";
import './ProfilePage.css';
import useUser from "../../hooks/useUser";
import Api from "../../actions/apiCallHelper";
import {FriendList} from "../../components/Friends/FriendList";
import {toast} from "react-hot-toast";
import {Loader} from "../../components/Common/Loader/Loader";
import {UserBlock} from "../../components/User/UserBlock";
import {useLocation, useNavigate, useParams} from "react-router-dom";

export const ProfilePage = () => {

    const {login} = useParams();
    const {user, setUser} = useUser();
    const [friends, setFriends] = useState();
    const [avatarFile, setAvatarFile] = useState();
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        fetchUserFriends();
    }, [login]);

    const fetchUserFriends = async () => {
        const api = new Api(true);
        try {
            const response = await api.getUserFriends(login);
            setFriends(response.data.friends);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const chooseAvatarFileHandler = (event) => {
        const file = event.target.files[0];
        setAvatarFile(file);
    }

    const changeAvatarHandler = async (e) => {
        e.preventDefault();

        const api = new Api(true);
        try {
            const response = await api.editUser({avatar: avatarFile});
            toast('Аватар обновлен!');
        } catch (e) {
            toast(e.message);
        } finally {
            console.log('finally');
        }
    }

    return (
        <div>
            <Header/>
            <div className='app-global-container'>
                <div className='main-block-content'>
                    {isLoading ?
                        <div className='loader-wrapper'>
                            <Loader useStyle={false} text={'Загрузка профиля'}/>
                        </div> :
                        <>
                            <UserBlock/>
                            <FriendList
                                friends={friends ?? []}
                                setFriends={setFriends}
                            />
                        </>
                    }
                </div>
            </div>
        </div>
    );
}