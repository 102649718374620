import {Constants} from "../../helpers/Constants";
import {useLocation} from "react-router-dom";
import './TagPlug.css';

export const TagPlug = () => {

    const location = useLocation();

    const tagPlug = 'Нет тегов';

        // (location.pathname === Constants.ROUTES.meme_create)
        //     ? 'Здесь будут теги' : 'Пока нет ни одного тега';

    return (
        <div className='tags-plug'>
            <span>{tagPlug}</span>
        </div>
    )
}
