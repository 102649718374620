import {createSlice} from '@reduxjs/toolkit'

const getInitUser = () => {
    const userInitStr = localStorage.getItem('user');
    const userInit = JSON.parse(userInitStr);

    return {
        id: userInit?.id,
        name: userInit?.name,
        description: userInit?.description,
        avatar: userInit?.avatar
    };
}
const initialState = getInitUser();

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserGlobal: (state, action) => {
            const currentUser = action.payload.userData;
            if (!currentUser) {
                localStorage.removeItem('user');
                state.id = null;
                return;
            }

            // console.log(initialState);
            // if (initialState === null) {
            //     localStorage.setItem('user', JSON.stringify(currentUser));
            //     state = {};
            // } else {
            //     console.log(currentUser);
            //     console.log(initialState);
            //
            //     localStorage.setItem('user', JSON.stringify(
            //         {...initialState, id: currentUser.id, name: currentUser.name, description: currentUser.description}
            //     ));
            // }

            localStorage.setItem('user', JSON.stringify(
                {...initialState,
                    id: currentUser.id,
                    name: currentUser.name,
                    description: currentUser.description,
                    avatar: currentUser.avatar,
                }
            ));

            state.id = currentUser.id;
            state.name = currentUser.name;
            state.description = currentUser.description;
            state.avatar = currentUser.avatar;
            console.log(state);
        }
    },
})

export const { setUserGlobal } = userSlice.actions

export default userSlice.reducer