import {Comment} from "./Comment";
import './CommentsList.css';
import React from "react";

export const CommentsList = ({comments, deleteComment}) => {

    return (
        <>
            <div className='meme-comment-wrapper'>
                <section className='meme-comments'>
                    {comments.length ? comments.map(comment => (
                        <Comment key={comment.id} comment={comment} deleteComment={deleteComment}/>
                    )): <p>Будьте первым, кто добавит комментарий!</p>}
                </section>
            </div>
        </>
    );
}