import React from "react";
import './CreateMemePage.css';
import {Header} from "../../components/Header/Header";
import CreateMemeWindow from "../../components/CreateMemeWindow/CreateMemeWindow";

export const CreateMemePage = () => {

    return (
        <>
            <Header/>
            <div className='app-global-container'>
                <div className={'create-meme-page'}>
                    <CreateMemeWindow/>
                </div>
            </div>
        </>
);
}