import {configureStore} from '@reduxjs/toolkit'
import {userSlice} from "./userSlice";
import {searchUserCollectionSlice} from "./searchUserCollectionSlice";
import {notificationSlice} from "./notificationSlice";
import {memesCollectionSlice} from "./memesCollectionSlice";
import {searchGlobalCollectionSlice} from "./searchGlobalCollectionSlice";

export const store = configureStore({
    reducer: {
        user: userSlice.reducer,
        searchUserCollection: searchUserCollectionSlice.reducer,
        searchGlobalCollection: searchGlobalCollectionSlice.reducer,
        notification: notificationSlice.reducer,
        memesCollection: memesCollectionSlice.reducer
    },
})