import {MemeCard} from "../MemeCard/MemeCard";
import './MemeCollection.css'
import {useLocation} from "react-router-dom";
import {Constants} from "../../helpers/Constants";
import {MemeCardGlobal} from "../MemeCard/MemeCardGlobal";
import {MemeCardSkeleton} from "../MemeCard/MemeCardSceleton";
import React from "react";

export const MemeCollection = ({memes = [], callbackRemove, isLoading = false, currPage = 1}) => {

    const location = useLocation();
    const CardMeme = location.pathname === Constants.ROUTES.home ? MemeCardGlobal : MemeCard;

    const getSkeleton = () => {

        const addSkeletons = () => {
            if (memes.length > 0 && currPage !== 1) {
                return <MemeCardSkeleton/>;
            }

            return (
                <>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                    <MemeCardSkeleton/>
                </>
            );
        }

        return <>
            {addSkeletons()}
        </>
    }

    return (
        <div className='memes-wrapper'>
            <section className='memes'>
                {
                    memes.map(meme => (
                        <CardMeme meme={meme} key={meme.id} callbackRemove={callbackRemove}/>
                    ))
                }
                {
                    isLoading ? getSkeleton() : ''
                }
            </section>
        </div>
    );
}