import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import Api from "../../actions/apiCallHelper";
import {Header} from "../../components/Header/Header";
import {Loader} from "../../components/Common/Loader/Loader";
import './DetailMemePage.css';
import useUser from "../../hooks/useUser";
import {MemeCardDetail} from "../../components/MemeCard/MemeCardDetail";
import {CommentsBlock} from "../../components/Comments/CommentsBlock";
import {BackButton} from "../../components/Common/BackButton/BackButton";
import {addComment, deleteComment} from "../../helpers/memeHelper";
import {Constants} from "../../helpers/Constants";
import {toast} from "react-hot-toast";
import {NotFoundPage} from "../NotFoundPage/NotFoundPage";

export const DetailMemePage = () => {

    const [meme, setMeme] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const { id} = useParams();

    const [isLoading, setIsLoading] = useState(true);
    const [notFound, setNotFound] = useState(false);
    const {user, setUser} = useUser();

    useEffect(() => {
        setNotFound(false);
        const fetchData = async () => {
            const api = new Api(user);
            try {
                let response = await api.getMemeById(id);
                const data = response.data;
                setMeme(data);
            } catch (e) {
                if (e.code === 404 || e.code === 403) {
                    setNotFound(true);
                }

                console.log(e);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, [id]);

    const addCommentCallback = async (commentText, modeComment) => {
        let memeId = modeComment === Constants.PRIVATE_MODE.private ? meme.id : null;

        if (meme.privateMode !== Constants.PRIVATE_MODE.public) {
            memeId = meme.id;
        }

        await addComment({
            commentText: commentText,
            memeFileId: meme.memeFile.id,
            memeId: memeId,
            callback: (response) => {
                setMeme((oldStateMeme) => {
                    oldStateMeme.memeFile.comments.unshift(response);
                    return {...oldStateMeme};
                });
            }
        });
    }

    const deleteCommentCallback = async (commentId) => {
        await deleteComment(commentId, () => {
            setMeme((oldStateMeme) => {
                oldStateMeme.memeFile.comments = oldStateMeme.memeFile.comments.filter(c => (c.id !== commentId));
                return {...oldStateMeme};
            });
            toast('Комментарий удален!');
        });
    }

    return (
        <div>
            {!notFound ?
                <>
                    <Header/>
                    <div>
                        {isLoading ? <Loader/> :
                            <div className='app-global-container'>
                                <div className='meme-detail-wrapper'>
                                    <BackButton text='Вернуться назад'/>
                                    <div className='meme-detail-content'>
                                        <MemeCardDetail meme={meme}/>
                                        <CommentsBlock addComment={addCommentCallback} deleteComment={deleteCommentCallback} comments={meme.memeFile.comments} meme={meme}/>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
                : <NotFoundPage/>
            }
        </div>);
}