import React from 'react';
import {Header} from "../../components/Header/Header";
import {ResetPasswordWindow} from "../../components/ResetPasswordWindow/ResetPasswordWindow";

export const ResetPasswordPage = () => {


    return (
        <div>
            <Header/>
            <div className='app-global-container'>
                <ResetPasswordWindow/>
            </div>
        </div>
    );
}
