import React, {useEffect, useState} from "react";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import Api from "../../actions/apiCallHelper";
import FriendsHelper from "../../helpers/friendsHelper";
import './UserBlock.css';
import {Avatar} from "../Avatar/Avatar";
import signOutSvg from "../../assets/images/sign-out.svg";
import {Constants} from "../../helpers/Constants";
import useUser from "../../hooks/useUser";
import useAccessToken from "../../hooks/useAccessToken";
import useRefreshToken from "../../hooks/useRefreshToken";

export const UserBlock = () => {

    const {login} = useParams();
    const [profileUser, setProfileUser] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();

    const {user, setUser} = useUser();
    const {accessToken, setAccessToken} = useAccessToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();

    console.log(user);
    const friendHelper = new FriendsHelper();

    useEffect(() => {
        fetchProfileUser();
    }, [login, user]);

    const fetchProfileUser = async () => {
        setIsLoading(true);
        const api = new Api(user);
        try {

            const userLogin = login ?? user.name;
            const response = await api.getUserByLogin(userLogin);
            const profileStatus = {status: response.data.status};
            const profileData = {...profileStatus, ...response.data.user};
            setProfileUser(profileData);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const updateStatusFriend = (status) => {
        setProfileUser({...profileUser, status: status});
    }

    const exit = () => {
        setAccessToken(false);
        setRefreshToken(false);
        setUser(null);
        navigate(Constants.ROUTES.signin);
    }

    return (
        <div className='main-user-block-wrapper'>
            <div className='main-user-block'>
                {(!isLoading) &&
                    <div className='user-block'>
                        <Avatar avatar={profileUser?.avatar} width={96} height={96}/>
                        <div className='user-block-name'>
                            <span>{profileUser?.name}</span>
                        </div>
                        {profileUser?.description && <div className='user-block-description'>{profileUser?.description}</div>}
                        {user?.id !== profileUser?.id ?
                            <div className='user-block-friend-button'>
                                {friendHelper.getButtonActionFriend(profileUser?.status, profileUser?.id, updateStatusFriend)}
                            </div> :
                            <div className='user-block-edit-buttons'>
                                <button
                                    className='user-block-edit'
                                    disabled={location.pathname === Constants.ROUTES.profile_edit}
                                    onClick={() => {
                                        navigate(Constants.ROUTES.profile_edit)
                                    }}
                                >
                                    Редактировать профиль
                                </button>
                                <button className='user-block-exit' onClick={exit}>
                                    <img src={signOutSvg} alt=''/>
                                </button>
                            </div>
                        }
                        {user?.id !== profileUser?.id &&
                            <div className='user-block-status mt8px'>
                                {friendHelper.convertStatus(profileUser?.status)}
                            </div>
                        }
                        <div className='user-block-statistics'>
                            <Link className='user-block-statistic-link' to={Constants.ROUTES.profile + profileUser.name}>
                                <div className='user-block-statistic-option'>
                                    <span>
                                        Друзья пользователя
                                    </span>
                                    <span className='user-block-statistic-count'>
                                        {profileUser?.countFriends}
                                    </span>
                                </div>
                            </Link>
                            <Link className='user-block-statistic-link' to={Constants.ROUTES.collection + profileUser.name}>
                                <div className='user-block-statistic-option'>
                                    <span>
                                        Мемов в коллекции
                                    </span>
                                    <span className='user-block-statistic-count'>
                                         {profileUser?.countMemes}
                                    </span>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}