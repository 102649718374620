import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    notifications: []
}

export const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state, action) => {
            state.notifications.unshift(action.payload.notification);
        },
        deleteNotification: (state, action) => {
            state.notifications = state.notifications.filter((notification) => {
               return notification.id !== action.payload.notificationId;
            });
        },
        setIsReadNotification: (state, action) => {
            state.notifications = state.notifications.map((notification) => {
                if (notification.id === action.payload.id) {
                    notification.read = true;
                }
                return notification;
            });
        },
        setNotifications: (state, action) => {
            state.notifications = action.payload.notifications;
        }
    },
})

export const {addNotification, setIsReadNotification, deleteNotification, setNotifications} = notificationSlice.actions

export default notificationSlice.reducer