import React from "react";
import './Modal.css';
import exitSvg from '../../../assets/images/exit.svg';

export const Modal = ({active=false, setActive, withCloseBtn=true, children}) => {
    return (
        <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
            <div className={active ? 'modal-content active' : 'modal'} onClick={e => e.stopPropagation()}>
                {withCloseBtn &&
                    <div className='close-button'>
                        <button className='modal-exit no-border-button' onClick={() => setActive(false)}>
                            <img src={exitSvg} alt=''/>
                        </button>
                    </div>
                }
                {children}
            </div>
        </div>
    );
}