import React, {useRef, useState} from "react";
import './AddCommentBlock.css';
import useUser from "../../hooks/useUser";
import addCommentSvg from '../../assets/images/add-comment.svg';
import {ModeFilter} from "../Filters/ModeFilter";
import {Constants} from "../../helpers/Constants";
import {ReactComponent as PrivateSvg} from '../../assets/images/private.svg';
import {ReactComponent as PublicSvg} from '../../assets/images/public.svg';
import {toast} from "react-hot-toast";

export const AddCommentBlock = ({addComment, meme=null}) => {

    const [text, setText] = useState('');
    const {user, setUser} = useUser();

    const textAreaRef = useRef();
    const minHeight = 35;
    const maxHeight = 120;

    const onChangeHandler = function(e) {
        const target = e.target;
        textAreaRef.current.style.height = `${minHeight}px`;
        textAreaRef.current.style.height = `${target.scrollHeight}px`;
        e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
    };

    const [modeComment, setModeComment] = useState(Constants.PRIVATE_MODE.public);

    const handleAddCommentButton = () => {
        if (!user) {
            toast('Авторизуйтесь, чтобы оставить комментарий!');
            return;
        }
        addComment(text, modeComment);
        setText('');
        textAreaRef.current.style.height = `${minHeight}px`;
    }

    return (
        <div className='meme-add-comment-wrapper'>
            <div className='meme-add-comment-container'>
                {/*<div className='comment-add-comment-avatar'>*/}
                {/*    <Avatar avatar={user?.avatar}/>*/}
                {/*</div>*/}
                <div className='meme-add-comment'>
                <textarea
                    ref={textAreaRef}
                    placeholder='Что вы думаете о меме?'
                    onChange={(e) => {
                        setText(e.target.value);
                        onChangeHandler(e);
                    }}
                    value={user !== null ? text : 'Необходимо авторизоваться!'}
                    disabled={user === null}
                >
                </textarea>
                    <button className='add-comment-button' onClick={handleAddCommentButton}>
                        <img src={addCommentSvg} alt='Отправить'/>
                    </button>
                </div>
            </div>
            {(meme !== null && meme.privateMode === Constants.PRIVATE_MODE.public) &&
                <div>
                    <ModeFilter
                        options={[
                            {
                                value: Constants.PRIVATE_MODE.public,
                                text: 'Публичный',
                                icon: PublicSvg
                            },
                            {
                                value: Constants.PRIVATE_MODE.private,
                                text: 'Приватный',
                                icon: PrivateSvg
                            },
                        ]}
                        mode={modeComment}
                        setMode={setModeComment}
                    />
                </div>
            }
        </div>
    );
}