import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useUser from "../../hooks/useUser";
import {useDispatch, useSelector} from "react-redux";
import Api from "../../actions/apiCallHelper";
import {toast} from "react-hot-toast";
import {Loader} from "../Common/Loader/Loader";
import {MemeCard} from "../MemeCard/MemeCard";
import {PrivateModeSelector} from "../PrivateModeSelector/PrivateModeSelector";
import {CreatableSelectTags} from "../CreatableSelectTags/CreatableSelectTags";
import {Input} from "../Common/Input/Input";
import {Button} from "../Common/Button/Button";
import './EditMemeWindow.css';
import {setGlobalCollection, setUserCollection} from "../../store/memesCollectionSlice";
import {setGlobalSearchDefaultSettings} from "../../store/searchGlobalCollectionSlice";
import {MemeCardSkeleton} from "../MemeCard/MemeCardSceleton";
import {MySelectTagsComponent} from "../CreatableSelectTags/MySelectTagsComponent";
import {BackButton} from "../Common/BackButton/BackButton";
import {PrivateModeOption} from "../PrivateModeSelector/PrivateModeOption";

export const EditMemeWindow = () => {

    let {id} = useParams();

    const memes = useSelector((state) => state.memesCollection.userCollection);

    const [tags, setTags] = useState([]);
    const [chosenTags, setChosenTags] = useState([]);

    const [meme, setMeme] = useState(null);

    const [userMemeName, setUserMemeName] = useState(null);

    const [loading, setLoading] = useState(true);
    const [loadingMemeSave, setLoadingMemeSave] = useState(false);
    const [privateMode, setPrivateMode] = useState(0);

    const {user, setUser} = useUser();

    const dispatch = useDispatch();

    useEffect(() => {

        setMeme(meme => {
            return {...meme, tags: chosenTags}
        });

    }, [chosenTags]);

    useEffect(() => {
        setMeme({...meme, privateMode:privateMode})
    }, [privateMode]);

    useEffect(() => {
        setMeme({...meme, userMemeName: userMemeName});
    }, [userMemeName])

    useEffect(() => {
        const fetchData = async () => {
            const api = new Api(true);

            try {
                let response = await api.getMemeById(id);
                const meme = response.data;
                setMeme(meme);

                setChosenTags(meme.tags);
                setPrivateMode(meme.privateMode);

                response = await api.getUserTagsByLogin(user.name);
                setTags(response.data);
                setUserMemeName(meme?.userMemeName);
            } catch (e) {
                toast(e.message);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, []);

    const changeUserMemeNameInputHandler = (event) => {
        const name = event.target.value;
        setUserMemeName(name);
    }

    const saveChanges = async () => {
        setLoadingMemeSave(true);
        const memeId = meme.id;
        const userMemeName = meme.userMemeName;
        const tagIds = meme.tags.map(t => t.id);

        const api = new Api(true);
        try {
            const response = await api.editMeme(memeId, tagIds, userMemeName, privateMode);

            const cloneMemes = memes.slice(0);
            const updatedMemes = cloneMemes.map((meme) => {
                if (meme.id === memeId) {
                    return response.data;
                }
                return meme;
            })
            dispatch(setUserCollection(updatedMemes));
            dispatch(setGlobalCollection([]));
            dispatch(setGlobalSearchDefaultSettings());

            toast('Мем обновлен!');
        } catch (e) {
            toast(e.message);
        } finally {
            setLoadingMemeSave(false);
        }
    }

    return (
        <div className='block-edit-meme'>
            {loading ?
                <>
                    <Loader text={'Загрузка...'} useStyle={false}/>
                </> :
                <>
                    <div>
                        {loadingMemeSave ? <MemeCardSkeleton/> : <MemeCard meme={meme}/>}
                    </div>
                    <div className='edit-meme-window'>
                        <div className='edit-meme-title'>
                            <span>Редактирование мема</span>
                        </div>
                        {!meme.memeFile.blocked ?
                            <PrivateModeSelector
                                privateMode={privateMode}
                                setPrivateMode={setPrivateMode}
                                text="Выберите модификатор доступа для мема:"
                            /> : <PrivateModeOption text='приватный' value={2} currentValue={privateMode} setValue={setPrivateMode}/>
                        }
                        <div className='form-edit-meme'>
                            <Input
                                placeholder='Введите имя мема'
                                value={userMemeName ?? ''}
                                onChange={changeUserMemeNameInputHandler}
                                setValue={setUserMemeName}
                            />
                            <MySelectTagsComponent
                                tags={tags}
                                setTags={setTags}
                                selectedTags={chosenTags}
                                setSelectedTags={setChosenTags}
                            />
                            <div>
                                <Button
                                    text='Сохранить изменения'
                                    onClick={saveChanges}
                                    maxWidth={true}
                                />
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
}
