import {Modal} from "../../Common/Modal/Modal";
import {MemeContent} from "../../MemeCard/MemeContent";
import {Button} from "../../Common/Button/Button";
import React, {useRef, useState} from "react";
import './ChooseFromSimilarMemesWindow.css';

export function ChooseFromSimilarMemesWindow({
 showWindowHasSimilarMeme,
 setShowWindowHasSimilarMeme,
 similarMemeFile,
 tempUrl,
 fileLink,
 createMemeHandler
}) {
    const firstRef = useRef();
    const secondRef = useRef();

    const myMemeVariant = 1;
    const memeFromDbVariant = 2;

    const [choice, setChoice] = useState(myMemeVariant);


    return (<Modal active={showWindowHasSimilarMeme} setActive={setShowWindowHasSimilarMeme}>
        <div className='window-choose-one-of-similar-wrapper'>
            <div className='window-choose-one-of-similar-header'>
                <div className='window-choose-one-of-similar-title'>Мем слишком похож...</div>
                <div className='window-choose-one-of-similar-text'>
                    Вы загружаете мем, уже имеющийся в системе.<br/>Выберите мем для добавления в коллекцию
                </div>
            </div>
            <div className='window-choose-one-of-similar-memes'>
                <div
                    className='variant-wrapper'
                    onClick={() => setChoice(myMemeVariant)}
                >
                    <div ref={firstRef} className={'variant' + (choice === myMemeVariant ? ' active-choice' : '')}>
                        <MemeContent
                            memeFile={{
                                id: null,
                                fileLink: tempUrl ?? fileLink,
                                type: 'image'
                            }}
                            containerRef={firstRef}
                            width={191}
                            height={200}
                        ></MemeContent>
                    </div>
                    <span className='variant-name'>Мой</span>
                </div>
                <div className='variant-wrapper' onClick={() => setChoice(memeFromDbVariant)}>
                    <div ref={secondRef} className={'variant' + (choice === memeFromDbVariant ? ' active-choice' : '')}>
                        <MemeContent
                            memeFile={{
                                id: null,
                                fileLink: similarMemeFile?.fileLink ?? null,
                                type: 'image'
                            }}
                            containerRef={secondRef}
                            width={191}
                            height={200}
                        ></MemeContent>
                    </div>
                    <span className='variant-name'>Немой</span>
                </div>
            </div>
            <Button text='Добавить мем' onClick={
                (e) => {
                    if (choice === memeFromDbVariant) {
                        createMemeHandler(e, false, similarMemeFile.id ?? null);
                        return;
                    }
                    createMemeHandler(e, false);
                }
            }/>
        </div>
    </Modal>);
}