import {toast} from "react-hot-toast";
import Api from "../actions/apiCallHelper";
import {Constants} from "./Constants";
import React from "react";

export default class FriendsHelper {
    addFriend = async (idFriend, callback) => {

        const api = new Api(true);
        try {
            const response = await api.addFriend(idFriend);
            callback(response.data?.status);
        } catch (e) {
            toast(e.message);
        }
    }

    removeFriend = async (idFriend, callback) => {
        const api = new Api(true);

        try {
            const response = await api.removeFriend(idFriend);
            callback(response.data?.status);
        } catch (e) {
            toast(e.message);
        }
    }

    convertStatus = (status) => {

        let message = '';
        let className = '';

        switch (status) {
            case Constants.STATUS_FRIENDSHIP.FRIEND_WITH_ME:
                message = 'Пользователь подписан на вас';
                className = 'friends-with-me';
                break;
            case Constants.STATUS_FRIENDSHIP.FRIEND:
                message = 'Заявка в друзья отправлена'
                className = 'friends';
                break;
            case Constants.STATUS_FRIENDSHIP.APPROVE_FRIEND:
                message = 'У вас в друзьях'
                className = 'friends-approve';
                break;
            default:
                message = 'Не в друзьях';
                className = 'not-friends';
                break;
        }

        if (message) {
            return (
                <div className={'user-block-status ' + className}>
                    {message}
                </div>
            )
        }

        return '';
    }

    getButtonActionFriend = (status, friendId, updateStatus) => {

        const addFriend = async () => {
            await this.addFriend(friendId, updateStatus);
        }

        const removeFriend = async () => {
            await this.removeFriend(friendId, updateStatus);
        }

        let btnClass = 'add-friend-button';
        let message = 'Добавить в друзья';
        let action = addFriend;

        switch (status) {
            case Constants.STATUS_FRIENDSHIP.FRIEND:
                message = 'Отозвать заявку';
                action = removeFriend;
                btnClass = 'remove-friend-button';
                break;
            case Constants.STATUS_FRIENDSHIP.APPROVE_FRIEND:
                message = 'Удалить из друзей';
                action = removeFriend;
                btnClass = 'remove-friend-button';
                break;
        }

        return (
            <div>
                <button onClick={action} className={btnClass + ' pointer'}>{message}</button>
            </div>
        );
    }
}