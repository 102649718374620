export const UserRowSkeleton = () => {
    return (
        <div className='skeleton'>
            <div className='user-wrapper'>
                <div>
                    <div className='skeleton-user-plug round'>
                    </div>
                </div>
                <div className='skeleton-user-name-plug'>
                </div>
            </div>
        </div>
    );
}