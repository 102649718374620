import {Constants} from "../../helpers/Constants";
import {Link} from "react-router-dom";
import './Notification.css';
import Api from "../../actions/apiCallHelper";
import {useDispatch} from "react-redux";
import {deleteNotification, setIsReadNotification} from "../../store/notificationSlice";
import {Avatar} from "../Avatar/Avatar";
import videoBlankImg from '../../assets/images/dog-logo-large.svg';
import useUser from "../../hooks/useUser";
import {useEffect, useRef} from "react";

export const Notification = ({notification}) => {

    const dispatch = useDispatch();
    const {user, setUser} = useUser();

    const notificationRef = useRef();

    useEffect(() => {
        let observer = new IntersectionObserver(function (entries) {
            entries.forEach(function (entry) {
                if (entry.isIntersecting) {
                    readNotification();
                }
            });
        });

        observer.observe(notificationRef.current);

        return (() => {
            observer.disconnect();
        });
    }, []);

    const rawDate = Date.parse(notification.created_at.date);
    const dateObj = new Date(rawDate);
    const notificationCreatedAtString = dateObj.toLocaleString('ru-Ru', {
        month: "long",
        day: "2-digit",
        hour: '2-digit',
        minute: '2-digit'
    });

    const commentPreview = (comment) => {
        if (comment.length < 15) {
            return comment;
        }

        return comment.slice(0, 15) + '...';
    }

    const getNotificationContent = () => {
        switch (notification.action) {
            case Constants.NOTIFICATION.rate_meme: {
                const user = notification.user;
                const meme = notification.object;

                return (
                    <>
                        <div className={'notification-meme-actor'}>
                            <Avatar avatar={user?.avatar}/>
                        </div>
                        <Link to={Constants.ROUTES.meme_detail + user.name + '/' + meme.id}
                              className='notification-meme-link'>
                            <div className={'notification-meme-content'}>
                                <div className={'notification-meme-message'}>
                                    Пользователь <Link className='notification-meme-user-link'
                                                       to={Constants.ROUTES.collection + user.name}>{user.name}</Link> оценил(а)
                                    ваш пост{meme.userMemeName ? ` «${meme.userMemeName}»` : ''}:
                                </div>
                                <div className={'notification-meme-object'}>
                                    <div className={'notification-meme-object-meme'}>
                                        <img className={'notification-meme-img'}
                                             src={meme.memeFile.type === Constants.IMAGE_TYPE ? meme.memeFile.fileLink : videoBlankImg}
                                             alt="meme"/>
                                    </div>
                                    <div className={'notification-meme-date'}>
                                        {notificationCreatedAtString}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </>
                )
            }
            case Constants.NOTIFICATION.comment_meme: {
                const user = notification.user;
                const meme = notification.object;
                const comment = notification.comment;
                return (
                    <>
                        <div className={'notification-meme-actor'}>
                            <Avatar avatar={user?.avatar}/>
                        </div>
                        <Link to={Constants.ROUTES.meme_detail + meme?.owner?.name + '/' + meme.id} className='notification-meme-link'>
                            <div className={'notification-meme-content'}>
                                <div className={'notification-meme-message'}>
                                    Пользователь <Link className='notification-meme-user-link'
                                                       to={Constants.ROUTES.collection + user.name}>{user.name}</Link> оставил(а)
                                    комментарий: {`«${commentPreview(comment)}»`} под вашим
                                    постом{meme.userMemeName ? ` «${meme.userMemeName}»` : ''}:
                                </div>
                                <div className={'notification-meme-object'}>
                                    <div className={'notification-meme-object-meme'}>
                                        <img className={'notification-meme-img'}
                                             src={meme.memeFile.type === Constants.IMAGE_TYPE ? meme.memeFile.fileLink : videoBlankImg}
                                             alt="meme"/>
                                    </div>
                                    <div className={'notification-meme-date'}>
                                        {notificationCreatedAtString}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </>
                )
            }
            case Constants.NOTIFICATION.new_friend: {

                const getMessage = (status) => {
                    switch (status) {
                        case -1:
                            return 'У вас новый друг - ';
                        case 0:
                            return 'Новая заявка в друзья от - '
                    }
                }

                const user = notification.user;
                const status = notification.status;

                return (
                    <>
                        <div className={'notification-meme-actor'}>
                            <Avatar avatar={user?.avatar}/>
                        </div>
                        <Link to={Constants.ROUTES.profile} className='notification-meme-link'>
                            <div className={'notification-meme-content'}>
                                <div className={'notification-meme-message'}>
                                    {getMessage(status)}<Link className='notification-meme-user-link'
                                                              to={Constants.ROUTES.collection + user.name}>{user.name}</Link>
                                </div>
                                <div className={'notification-meme-object'}>
                                    <div className={'notification-meme-object-meme'}>
                                    </div>
                                    <div className={'notification-meme-date'}>
                                        {notificationCreatedAtString}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </>
                )
            }
            case Constants.NOTIFICATION.file_uploaded: {

                const meme = notification.object;

                return (
                    <>
                        Загружено видео для <Link to={Constants.ROUTES.meme_detail + meme.id}>мема{meme.id}</Link>
                    </>
                )
            }

            default: {
                return '?'
            }
        }
    }

    const deleteHandler = async () => {
        const api = new Api(true);
        try {
            console.log(notification);
            await api.deleteNotification(notification.id);
            dispatch(deleteNotification({notificationId: notification.id}));
        } catch (e) {
            console.log(e);
        }
    }

    const readNotification = async () => {
        if (!notification.read) {
            const api = new Api(user);
            try {
                const response = await api.readNotification(notification.id);
            } catch (e) {
                console.log(e);
            } finally {
                dispatch(setIsReadNotification(notification));
            }
        }
    }

    return (
        <div className='notification-meme' ref={notificationRef}>
            {!notification.read && <div className='notification-meme-read-dot'></div>}
            {getNotificationContent()}
        </div>
    );
}