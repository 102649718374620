import React, {useState} from "react";
import './SignInWindow.css';
import {Button} from "../Common/Button/Button";
import {toast} from "react-hot-toast";
import Api from "../../actions/apiCallHelper";
import {Modal} from "../Common/Modal/Modal";
import useUser from "../../hooks/useUser";
import {useNavigate} from "react-router-dom";
import useAccessToken from "../../hooks/useAccessToken";
import useRefreshToken from "../../hooks/useRefreshToken";
import {getFirebaseToken} from "../../helpers/firebase";
import jwtDecode from "jwt-decode";
import {Constants} from "../../helpers/Constants";
import {Input} from "../Common/Input/Input";
import {useDispatch} from "react-redux";
import {setUserCollection} from "../../store/memesCollectionSlice";

function SignInWindow() {

    const {user, setUser} = useUser();

    const [email, setEmail] = useState();
    const [modalResetPasswordActive, setModalResetPasswordActive] = useState(false);
    const [modalMessagePasswordReset, setModalMessagePasswordReset] = useState(false);

    const dispatch = useDispatch();

    const [password, setPassword] = useState();
    const [errorMsg, setErrorMsg] = useState('');
    const navigate = useNavigate();

    const {accessToken, setAccessToken} = useAccessToken();
    const {refreshToken, setRefreshToken} = useRefreshToken();

    const loginUser = async ({email, password}) => {

        const api = new Api();
        try {
            let firebaseToken = null;
            const permission = await Notification.requestPermission();
            if (permission === 'granted') {
                try {
                    firebaseToken = await getFirebaseToken();
                } catch (e) {
                    console.log(e);
                }
            } else if (permission === 'denied') {
                console.log('Вы отказались получать оповещения!')
            }

            const response = await api.login({
                email: email,
                password: password,
                firebaseToken: firebaseToken
            });
            const data = response.data;

            setAccessToken(data?.token);
            setRefreshToken(data?.refresh_token);

            const tokenData = jwtDecode(data?.token);
            setUser(tokenData);

            navigate(Constants.ROUTES.my_collection);
            dispatch(setUserCollection([]));
        } catch (e) {
            toast('Неверный логин или пароль!');
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();

        await loginUser({
            email: email,
            password: password
        });
    }

    const resetPassword = async () => {
        if (!email) {
            toast('Укажите электронную почту!');
            return;
        }
        const api = new Api();
        try {
            const response = await api.resetPassword(email);
            setModalResetPasswordActive(false);
            setModalMessagePasswordReset(true);
        } catch (e) {
            if (e.code === 404 || e.code === 400) {
                toast(e.message);
            }
            console.log(e);
        }
    };

    const onEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const onPasswordChange= (e) => {
        setPassword(e.target.value);
    }

    return (
        <>
            <form onSubmit={handleSubmit} className='login-window'>
                <span className='signin-header'>Вход</span>
                <div className='signin-input-area'>
                    <Input
                        placeholder='Электронная почта'
                        value={email}
                        setValue={setEmail}
                        onChange={onEmailChange}
                        type='email'
                    />
                    <Input
                        placeholder='Пароль'
                        type='password'
                        value={password}
                        setValue={setPassword}
                        onChange={onPasswordChange}
                    />
                    <div className='underline-link-button' onClick={() => setModalResetPasswordActive(true)}>
                        Восстановить пароль
                    </div>
                </div>
                <Button fontSize={16} text='Войти' submit={true}/>
                <div className='signup-link-container'>
                    <span>
                        Ещё нет аккаунта?
                    </span>
                    <div className='underline-link-button' onClick={() => navigate(Constants.ROUTES.signup)}>
                        Зарегистрироваться
                    </div>
                </div>
            </form>
            <Modal
                active={modalResetPasswordActive}
                setActive={setModalResetPasswordActive}
            >
                <div className='modal-reset-password'>
                    <div className='reset-password-modal-text-container'>
                        <span className='reset-password-modal-title'>Восстановление пароля</span>
                        <span className='reset-password-modal-text'>
                            Укажите электронную почту, на нее будет отправлено письмо с
                            ссылкой на восстановление пароля
                        </span>
                    </div>
                    <Input placeholder='Электронная почта' value={email} setValue={setEmail} onChange={onEmailChange}></Input>
                    <Button fontSize={16} text='Подтвердить восстановление' onClick={resetPassword}/>
                </div>
            </Modal>
            <Modal
                active={modalMessagePasswordReset}
                setActive={setModalMessagePasswordReset}
            >
                <div className='modal-reset-password'>
                    <div className='reset-password-modal-text-container'>
                        <span className='reset-password-modal-title'>Подтверждение</span>
                        <span className='reset-password-modal-text'>
                            {`Для восстановления пароля, мы отправили письмо с ссылкой на почту: "${email}"`}
                        </span>
                    </div>
                    <Button fontSize={16} text='Хорошо' onClick={() => setModalMessagePasswordReset(false)}/>
                </div>
            </Modal>
        </>
    );
}

export default SignInWindow;